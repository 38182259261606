import React from 'react';
import RatingRow from './RatingRow';
import { RightColumn } from './style';
import { v4 as uuid } from 'uuid';
import { ReviewsMap } from './types';

const ratings = Array(5).fill(0);

interface ReviewsDetailsProps {
    reviewsMap: ReviewsMap;
    reviewsCount: number;
}

const ReviewsDetails = ({ reviewsMap, reviewsCount }: ReviewsDetailsProps) => (
    <RightColumn>
        {ratings.map((_i, index) => {
            const ratingType = index + 1;
            const count = reviewsMap[ratingType] || 0;
            const percentage = reviewsCount > 0
                ? Math.round(count / reviewsCount * 100)
                : 0;

            return (
                <RatingRow
                    key={uuid()}
                    ratingType={ratingType}
                    percentage={percentage} />
            );
        })}
    </RightColumn>
);

export default ReviewsDetails;
