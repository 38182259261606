import React from 'react';
import { ClassNamed } from '../../../../shared';

const UploadIcon = ({ className }: ClassNamed) => {
    return (
        <svg className={className} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M26.7344 14.5C26.7344 21.2569 21.2569 26.7344 14.5 26.7344C7.74314 26.7344 2.26562 21.2569 2.26562 14.5C2.26562 7.74314 7.74314 2.26562 14.5 2.26562C21.2569 2.26562 26.7344 7.74314 26.7344 14.5ZM29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5ZM10.0237 15.4612L13.1406 12.3443V21.75H15.8594V12.3443L18.9763 15.4612L20.8987 13.5388L15.4612 8.10128C14.9304 7.57041 14.0696 7.57041 13.5388 8.10128L8.10128 13.5388L10.0237 15.4612Z" fill="currentColor"/>
        </svg>
    );
};

export default UploadIcon;
