import { Divider, Image } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DistrictSelect from './DistrictSelect';
import { CheckboxState, City, District } from './types';
import { CitiesSelectWrapper, CollapsibleDiv, DeselectAll, MapWrapper, SelectionDetailsWrapper, TitleWrapper, ToggleIcon } from './style';
import { getSelectedTranslation } from './utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { clearAll, sd2, selectAll, workAreas, workAreasDisclaimer } from '../../../helpers/dictionary';
import Paragraph from 'antd/lib/typography/Paragraph';


interface CitiesSelectProps {
    cities: City[];
    getData(ids: number[]): void;
    preselected?: District[];
}

const CitySelect = ({ preselected = [], cities, getData }: CitiesSelectProps) => {
    useEffect(() => {
        const initialSelectionIds = preselected.map(d => d.id);
        getData(initialSelectionIds);
    }, []);

    const initialToggleState: Record<number, boolean> = {};
    const initialSelectedState =  preselected.reduce((acc: Record<number, CheckboxState>, district) => {
        const { cityId, id: districtId } = district;
        if(acc[cityId]){
            acc[cityId] = { ...acc[cityId], [districtId]: true };
        } else {
            acc[cityId] = { [districtId]: true };
        }
        return acc;
    }, {});
    cities.forEach(({ id }) => {
        initialToggleState[id] = false;
        if(!initialSelectedState[id]){
            initialSelectedState[id] = {};
        }
    });

    const [toggle, setToggle] = useState(initialToggleState);
    const [selection, setSelection] = useState(initialSelectedState);
    const lang = useSelector((state: { settings: { lang: 'eng' | 'uz' | 'ru' } }) => state.settings.lang);

    const getSelected = (currentSelection = selection) => {
        const keys = Object.keys(currentSelection);
        // transhorm to [{ [districtId]: boolean }]
        const selectionMap = keys.reduce((acc: CheckboxState, key) => ({ ...acc, ...currentSelection[parseInt(key,)]}), {});
        const districtKeys = Object.keys(selectionMap);
        const selectedDistricts = districtKeys.reduce((acc: number[], key)=> {
            const parsedKey = parseInt(key, 10);
            const isSelected = selectionMap[parsedKey];
            if(isSelected){
                acc.push(parsedKey);
            }
            return acc;
        }, []);

        return selectedDistricts;
    };

    const setHandleSetSelection = (data: Record<number, CheckboxState>) => {
        setSelection(data);

        const currentSelection = getSelected(data);
        getData(currentSelection);
    };

    return (
        <div className="questions-section full-vw">
            <Title level={2}  style={{fontWeight:700, fontSize:'24px'}}>{workAreas[lang]}</Title>
            <div  className="infoToolTip"  style={{ marginLeft: '-35px',  marginBottom:'30px',display: '-webkit-box' }}>
                <img style={{ width: '35px' }} src="/assets/images/attention-icon.png" />
                <div style={{
                    width: '630px', background: '#F9F7FD',

                    border: '1px solid #5D5FEF',
                    padding: '15px',
                    boxSizing: 'border-box'
                }}><p style={{fontSize:'13px',fontWeight:500}} dangerouslySetInnerHTML={{__html:sd2[lang]}}>

                    </p></div></div>
            <Paragraph>
                {workAreasDisclaimer[lang]}
            </Paragraph>
            <Divider style={{ margin: '12px 0' }} />
            <CitiesSelectWrapper>
                <div>
                    {cities.map(city => {
                        const { id, districts } = city;
                        const isToggled = toggle[id];

                        const handleTogleSet = () => {
                            setToggle({ ...toggle, [id]: !toggle[id] });
                        };
                        const handleSelectionSet = (e: CheckboxChangeEvent) => {
                            const targetId = parseInt(e.target.name, 10);
                            const currentState = selection[id][targetId];
                            setHandleSetSelection({ ...selection, [id]: { ...selection[id], [targetId]: !currentState }});
                        };

                        const selectedDistricts = selection[id];
                        const selected = Object.values(selectedDistricts).reduce((acc: number, val) => {
                            if(val){
                                acc++;
                            }
                            return acc;
                        }, 0);

                        const allSelected = selected === districts.length;

                        const handleSelectDeselectAll = () => {
                            if(allSelected){
                                setHandleSetSelection({ ...selection, [id]: {} });
                            } else {
                                const allDistricts = districts.reduce((acc: CheckboxState, { id }) => ({ ...acc, [id]: true }),{});
                                setHandleSetSelection({ ...selection, [id]: allDistricts });
                            }
                        };

                        const total = districts.length;

                        return (
                            <div key={id}>
                                <TitleWrapper onClick={handleTogleSet}>
                                    <Title style={{ margin: 0 }} level={5}>
                                        {city[lang]}
                                    </Title>
                                    <ToggleIcon toggled={isToggled} />
                                </TitleWrapper>
                                <SelectionDetailsWrapper>
                                    <span>
                                        {getSelectedTranslation({ selected, total, lang })}
                                    </span>
                                    <DeselectAll onClick={handleSelectDeselectAll}>
                                        {(allSelected ? clearAll : selectAll)[lang]}
                                    </DeselectAll>
                                </SelectionDetailsWrapper>
                                <CollapsibleDiv showDropdown={isToggled}>
                                    <DistrictSelect
                                        selected={selectedDistricts}
                                        setSelection={handleSelectionSet}
                                        districts={districts}
                                    />
                                </CollapsibleDiv>
                                <Divider style={{ margin: '12px 0' }} />
                            </div>
                        );
                    })}
                </div>
                <MapWrapper>
                    <Image
                        style={{width:'400px',height:'400px'}}
                        src={lang==='eng'?'/assets/images/eng.jpg':lang==='uz'?'/assets/images/uz.jpg':'/assets/images/eng.jpg'}
                        alt=""
                    />
                </MapWrapper>
            </CitiesSelectWrapper>
        </div>
    );
};

export default CitySelect;
