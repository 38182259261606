import React from 'react';
import { NumberWithStar, ProgressBar, RatingRowContainer } from './style';

interface RatingRowProps {
    percentage: number;
    ratingType: number;
}

const RatingRow = ({ percentage, ratingType }: RatingRowProps) => {
    return (
        <RatingRowContainer>
            <NumberWithStar>{ratingType}</NumberWithStar>
            <ProgressBar rating={percentage} />
            <span>{percentage}%</span>
        </RatingRowContainer>
    );
};

export default RatingRow;
