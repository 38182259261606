import styled from 'styled-components';

export const StarsContainer = styled.div<{ small?: boolean }>`
    unicode-bidi: bidi-override;
    user-select: none;
    color: #E2E2E9;
    margin-right: 24px;
    ${({ small }) => small
        ? `
            width: 48px;
            font-size: 12px;
            height: 12px;
            line-height: 12px;
        `
        : `
            width: 96px;
            font-size: 24px;
            height: 24px;
            line-height: 24px;
        `}
    position: relative;
    padding: 0;
    :hover {
        cursor: pointer;
    }
    > * {
        user-select: none;
    }
`;

export const ProgressBar = styled.div<{ rating: number | null}>`
    display: block;
    width: 100%;
    background-color: #E8EBF3;
    height: 6px;
    border-radius: 8px;
    position: relative;
    :before {
        content: '';
        display: block;
        width: 100%;
        height: 6px;
        background-color: var(--color-green);
        border-radius: 8px;
        ${({ rating }) => `width: ${rating ? `${rating}%` : '0'};`}
    }
`;

export const Star = styled.span<{ highlight: boolean }>`
    color: ${({ highlight }) => highlight ? `var(--color-green)`: `#E8EBF3`};
`;

export const RatingText = styled.span`
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #565459;
`;

export const RatingContainer = styled.div`
    display: grid;
    grid-template-rows: 20px auto;
    gap: 10px 0;
    justify-items: center;
`;