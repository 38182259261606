import NextImage, { ImageLoaderProps, ImageProps as NextImageProps } from 'next/image';
import React from 'react';

const loader = ({ src/* , width, quality */ }: ImageLoaderProps) => {
    return src;
};


const Image = (props: NextImageProps) => {
    return (
        <NextImage
            quality= {70}
            priority={true}
            loader={loader}
            {...props}
        />
    );
};

export default Image;
