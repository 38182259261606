import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VerificationDocument } from '../../../../shared';
import axios from '../../../../utils/axios';
import { SectionContainer, SectionTitle } from '../style';
import FileUpload from './FileUpload';
import { Body15, ProfileVerificationHintContainer } from './style';
import UploadedDocuments from './UploadedDocuments';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';

const ProfileVerification = () => {
    const { t } = useTranslation('profile');
    const [documents, setDocuments] = useState<VerificationDocument[]>([]);
    const setNewDocuments = (newDocuments: VerificationDocument[]) => {
        setDocuments([...documents, ...newDocuments]);
    };

    useEffect(() => {
        const fetch = async () => {
            await axios.get<VerificationDocument[]>(`${process.env.BACKEND_URL}/api/v3/verification-documents`)
                .then(({ data }) => {
                    setDocuments(data);
                });
        };

        fetch();
    }, []);

    return (
        <SectionContainer>
            <SectionTitle>
                {t('profileVerification')}
            </SectionTitle>
            <ProfileVerificationHintContainer>
                <Body15>
                    {t('profileVerificationHint')}
                </Body15>
                <CheckCircleFilled style={{ fontSize: 40, color: 'var(--color-green-pantone)' }} />
            </ProfileVerificationHintContainer>
            <UploadedDocuments documents={documents} />
            <FileUpload setDocuments={setNewDocuments} />
        </SectionContainer>
    );
};

export default ProfileVerification;
