import React from 'react';
import { StarsTop, StarsBottom, StarsContainer } from './style';

interface StarRatingProps {
    rating: number;
    small?: boolean;
}

const StarRating = ({ rating, small }: StarRatingProps) => {
    return (
        <StarsContainer small={small} >
            <StarsTop rating={rating}>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
            </StarsTop>
            <StarsBottom>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
            </StarsBottom>
        </StarsContainer>
    );
};

export default StarRating;
