import React, { useState } from 'react';
import styled from 'styled-components';
import Flex from './Flex';

import ShowIcon from '../../svgs/show.svg';

export const FieldGroupCss = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3.2rem;
  position: relative;

  label span {
    color: var(--color-red);
  }
  .password-show {
    position: absolute;
    right: 0.8rem;
    bottom: 0.6rem;
    outline: none;
    fill: #b5b5be;
    cursor: pointer;
  }
  .phone-start {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1.5rem;
    border-right: 2px solid #e2e2e9;
    background: #efefef;
    color: #888;
  }
  input {
    margin-top: 0.8rem;
    border: 2px solid #e2e2e9;
    border-radius: 4px;
    padding: 1.4rem;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &.with-phone-start {
      padding-left: 8rem !important;
      outline: none;
    }
    &.field-error {
      border-color: var(--color-red);
    }
  }
`;

const FieldGroup = ({
    label,
    type = 'text',
    placeholder = '',
    required,
    handleChange,
    isError,
    loginByNumber,
    id,
}) => {
    const field = () => {
        if (type === 'password') {
            const [showPassword, setShowPassword] = useState(false);
            return (
                <>
                    <span
                        className="password-show"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                        <ShowIcon />
                    </span>
                    <input
                        id={id && id}
                        type={showPassword ? 'text' : type}
                        placeholder={placeholder}
                        onChange={(e) => handleChange(e.target.value)}
                        className={isError ? 'field-error' : ''}
                        required
                    />
                </>
            );
        }

        if (loginByNumber) {
            return (
                <>
                    <span
                        className="phone-start"
                        // eslint-disable-next-line no-undef
                        onClick={() => setShowPassword((prevState) => !prevState)}
                    >
            +998
                    </span>
                    <input
                        id={id && id}
                        type="number"
                        placeholder={placeholder}
                        onChange={(e) => handleChange(e.target.value)}
                        className={
                            isError ? 'field-error with-phone-start' : ' with-phone-start'
                        }
                        required
                    />
                </>
            );
        }

        return (
            <input
                id={id && id}
                type={type}
                placeholder={placeholder}
                onChange={(e) => handleChange(e.target.value)}
                className={isError ? 'field-error' : ''}
                required
            />
        );
    };

    return (
        <FieldGroupCss>
            <Flex direction="column">
                <label htmlFor={id && id}>
                    {label} {required && <span>*</span>}
                </label>
                {field()}
            </Flex>
        </FieldGroupCss>
    );
};

export default FieldGroup;
