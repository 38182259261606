import { FormItemProps, Form } from 'antd';
import styled from 'styled-components';

export const GetConfirmationCodeButton = styled.button`
    background: var(--color-flickr-blue) !important;
    width: 100%;
    margin-bottom: 1rem !important;
    display: inline-block;
    color: white !important;
    border-radius: 4px;
    outline: none;
    &:disabled {
        background: #888 !important;
        &:hover {
            cursor: not-allowed;
        }
    }
`;

interface CenteredFormContainerProps {
    disableMinHeight?: boolean;
}

export const CenteredFormContainer = styled.div<CenteredFormContainerProps>`
    margin: 0 auto;
    ${({ disableMinHeight }) => !disableMinHeight && `min-height: 75vh;`}
    padding: 4rem 0;
    @media (min-width: 768px) {
        width: 400px;
    }
`;

export const FullWidthFormItem = styled((props: FormItemProps) => <Form.Item {...props} />)`
    label {
        width: 100%;
    }
`;