import styled from 'styled-components';

export const PaginationContainer = styled.div`
    height: 30px;
    display: flex;
    user-select: none;
    justify-self: end;
`;

export const ChevronContainer = styled.span<{ disabled?: boolean }>`
    width: 30px;
    height: 30px;
    display: flex;
    color: ${({ disabled }) => disabled ? `#a2a2a2` : `var(--color-flickr-blue)`};
    border: solid 0.5px var(--color-flickr-blue);
    :hover {
        cursor: ${({ disabled }) => disabled ? `not-allowed` : `pointer`};
    }
`;

export const PageNumberContainer = styled.span`
    border-top: solid 0.5px var(--color-flickr-blue);
    border-bottom: solid 0.5px var(--color-flickr-blue);
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 12px;
`;