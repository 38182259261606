import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NoBreakSpace } from '../../../helpers/constants';
import dictionary, { errorCodes } from '../../../helpers/dictionary';
import { COUNTRY_CODE } from '../constants';
import { formatTime } from '../SignupForm/utils';
import { codeValidationRule, phoneNumberValidation, requiredFieldValidationMessages } from '../validation';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTimer } from '../../../helpers/useTimer';
import { resendValidationCode, sendPasswordResetCode } from '../../../api';
import { AxiosPromise } from 'axios';
import { ApiErrorResponse } from '../../../shared';
import { FullWidthFormItem } from '../style';

interface FormValues {
    phone: number;
    code: string;
    password: string;
}

type FormState ='prestine' |'validationRequestStarted' |'validationRequestFinished' | 'codeRequestError' | 'formSubmitError';

interface SubmitValues {
    phone: number;
    countryCode: string;
    code: string;
    password: string;
}

interface ResetPasswordFormProps {
    onSubmit(args: SubmitValues): Promise<void>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCodeApiCall?: (args: { countryCode: string; phone: number }) => Promise<void> | AxiosPromise<any>;
    submitText?: string;
    isPro?: boolean;
}

const ResetPasswordForm = ({ submitText, onSubmit, isPro, getCodeApiCall }: ResetPasswordFormProps) => {
    const [sendAgainActive, setSendAgainActive] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [formState, setFormState] = useState<FormState>('prestine');
    const [form] = useForm<FormValues>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lang = useSelector((state: any) => state.settings.lang as string);
    const [prevCode, setPrevCode] = useState('');

    const displaySendCode = formState === 'validationRequestStarted' || formState === 'prestine' || formState === 'codeRequestError';
    const displaySubmitButton = formState === 'validationRequestFinished';

    const onTimeOver = () => {
        setSendAgainActive(true);
    };
    const { reset: resetTimer, start: startTimer, time } = useTimer({ endTime: 0, timerType: 'DECREMENTAL', initialTime: 60, onTimeOver });

    const getCodeRequest = getCodeApiCall || sendPasswordResetCode;
    const handleGetCodeClick = async () => {
        const { phone } = await form.validateFields();
        try {
            setFormState('validationRequestStarted');
            await getCodeRequest({ countryCode: COUNTRY_CODE, phone });
            setFormState('validationRequestFinished');
            setSendAgainActive(false);
            startTimer();
        } catch (error) {
            const { response: { data: { errors } } } = error as ApiErrorResponse;
            const formErrors = errors.map(({ param, errorCode, msg }) => {
                const errorMessage = errorCodes?.[errorCode]?.[lang] || msg;
                return {
                    name: param,
                    errors: [errorMessage],
                };

            });
            form.setFields(formErrors);
            setFormState('codeRequestError');
        }
    };

    const sendCodeAgain = async () => {
        setSendAgainActive(false);
        const { phone } = await form.validateFields(['phone']);

        resetTimer();
        try {
            await resendValidationCode({ phone, countryCode: COUNTRY_CODE, isPro, });
            startTimer();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };

    const onFinish = async (values: FormValues) => {
        setIsFormSubmitting(true);
        const { code, password, phone } = values;
        try {
            await onSubmit({
                code,
                password,
                phone,
                countryCode: COUNTRY_CODE,
            });


        } catch (error) {
            const { response: { data: { errors } } } = error as ApiErrorResponse;
            const formErrors = errors.map(({ param, errorCode, msg }) => {
                const errorMessage = errorCodes?.[errorCode]?.[lang] || msg;
                return {
                    name: param,
                    errors: [errorMessage],
                };

            });
            form.setFields(formErrors);
            setIsFormSubmitting(false);
        }
        setIsFormSubmitting(false);
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: nextValue } = e.target;
        const nextLength = nextValue.length;
        const isInsert = nextLength > prevCode.length;
        if(nextLength === 3 && isInsert){
            form.setFields([{ name: 'code', value: `${nextValue}-` }]);
            setPrevCode(`${nextValue}-`);
        } else {
            setPrevCode(nextValue);
        }
    };


    return (
        <Form
            validateMessages={requiredFieldValidationMessages(lang)}
            size='large'
            validateTrigger={['onBlur', 'onSubmit']}
            name='registerForm'
            onFinish={onFinish}
            layout='vertical'
            form={form}
        >
            <Form.Item
                hasFeedback
                name="phone"
                // eslint-disable-next-line import/no-named-as-default-member
                label={isPro ? undefined : dictionary.phoneNumber[lang]}
                rules={phoneNumberValidation}
            >
                <Input addonBefore={`+${COUNTRY_CODE}`} />
            </Form.Item>
            {displaySendCode
                ? (
                    <Form.Item>
                        <Button
                            onClick={handleGetCodeClick}
                            loading={formState === 'validationRequestStarted'}
                            htmlType="button"
                            block
                            type="primary"
                        >
                            {dictionary.recieveCode[lang]}
                        </Button>
                    </Form.Item>
                ) : null}
            {formState === 'validationRequestFinished'
                ? (
                    <>
                        <FullWidthFormItem
                            hasFeedback
                            required
                            name='code'
                            label={
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography.Text>
                                        {dictionary.enterSmsCode[lang]}
                                    </Typography.Text>
                                    <Button style={{ marginLeft: 'auto' }} size='small' onClick={sendCodeAgain} disabled={!sendAgainActive} type='text'>
                                        {dictionary.recieveCodeAgain[lang]}
                                        {NoBreakSpace}
                                        {time > 0 ? formatTime(time) : null}
                                    </Button>
                                </div>
                            }
                            rules={codeValidationRule}
                        >
                            <Input onChange={handleCodeChange} placeholder="000-000" autoFocus name='code' />
                        </FullWidthFormItem>
                        <Form.Item
                            hasFeedback
                            required
                            name='password'
                            label={dictionary.createPassword[lang]}
                            rules={[{ required: true }, { min: 5, max: 32 }]}
                        >
                            <Input.Password
                                name='password'
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </>
                ): null}
            {displaySubmitButton
                ? (
                    <Form.Item>
                        <Typography.Paragraph>{isPro ? dictionary.termsOfUseProceed[lang] : dictionary.termsOfUse[lang]}</Typography.Paragraph>
                        <Button size='large' disabled={isFormSubmitting} htmlType="submit" block type="primary">
                            {submitText || dictionary.resetPassword[lang]}
                        </Button>
                    </Form.Item>
                ) : null}
        </Form>
    );
};

export default ResetPasswordForm;
