import React, { useState } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import dictionary, { Business_Name, errorCodes, First_name } from '../../../helpers/dictionary';
import { useSelector } from 'react-redux';
import TermsOfUseDisclaimer from './TermsOfUseDisclaimer';
import { resendValidationCode, signupWithPhoneNumber } from '../../../api';
import { NoBreakSpace } from '../../../helpers/constants';
import { useTimer } from '../../../helpers/useTimer';
import { codeValidationRule, phoneNumberValidation, requiredFieldValidationMessages } from '../validation';
import { COUNTRY_CODE } from '../constants';
import { formatTime } from './utils';
import { FullWidthFormItem } from '../style';
import { ApiErrorResponse } from '../../../shared';

interface FormValues {
    firstName: string;
    lastName: string;
    phone: number;
    code: string;
    password: string;
}

interface SubmitValues {
    firstName: string;
    phone: number;
    countryCode: string;
    code: string;
    password: string;
}

type FormState ='prestine' |'validationRequestStarted' |'validationRequestFinished' | 'codeRequestError' | 'formSubmitError';

type SignupFormProps = {
    onSubmit(args: SubmitValues): Promise<void>;
    isPro?: boolean;
};

const SignupForm = ({ onSubmit, isPro = false }: SignupFormProps) => {
    const [form] = Form.useForm<FormValues>();
    const [prevCode, setPrevCode] = useState('');
    const [formState, setFormState] = useState<FormState>('prestine');
    const [sendAgainActive, setSendAgainActive] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const onTimeOver = () => {
        setSendAgainActive(true);
    };
    const { reset: resetTimer, start: startTimer, time } = useTimer({ endTime: 0, timerType: 'DECREMENTAL', initialTime: 60, onTimeOver });

    const sendCodeAgain = async () => {
        setSendAgainActive(false);
        const { phone } = await form.validateFields(['phone']);

        resetTimer();
        try {
            await resendValidationCode({ phone, countryCode: COUNTRY_CODE });
            startTimer();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lang = useSelector((state: any) => state.settings.lang as string);

    const handleGetCodeClick = async () => {
        const { phone, firstName, lastName } = await form.validateFields();
        try {
            setFormState('validationRequestStarted');
            await signupWithPhoneNumber({ firstName, lastName, countryCode: COUNTRY_CODE, phone });
            setFormState('validationRequestFinished');
            setSendAgainActive(false);
            startTimer();
        } catch (error) {
            const { response: { data: { errors } } } = error as ApiErrorResponse;
            const formErrors = errors.map(({ param, errorCode, msg }) => {
                const errorMessage = errorCodes?.[errorCode]?.[lang] || msg;
                return {
                    name: param,
                    errors: [errorMessage],
                };

            });
            form.setFields(formErrors);
            setFormState('codeRequestError');
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    const onFinish = async (values: FormValues) => {
        setIsFormSubmitting(true);
        const { firstName, code, password, phone } = values;
        try {
            await onSubmit({
                firstName,
                code,
                password,
                phone,
                countryCode: COUNTRY_CODE,
            });

        } catch (error) {
            const { response: { data: { errors } } } = error as ApiErrorResponse;
            const formErrors = errors.map(({ param, errorCode, msg }) => {
                const errorMessage = errorCodes?.[errorCode]?.[lang] || msg;
                return {
                    name: param,
                    errors: [errorMessage],
                };

            });
            form.setFields(formErrors);
            setIsFormSubmitting(false);
        }
        setIsFormSubmitting(false);
    };

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: nextValue } = e.target;
        const nextLength = nextValue.length;
        const isInsert = nextLength > prevCode.length;
        if(nextLength === 3 && isInsert){
            form.setFields([{ name: 'code', value: `${nextValue}-` }]);
            setPrevCode(`${nextValue}-`);
        } else {
            setPrevCode(nextValue);
        }
    };

    const displaySendCode = formState === 'validationRequestStarted' || formState === 'prestine' || 'codeRequestError';
    const displaySubmitButton = formState === 'validationRequestFinished';

    return (
        <Form
            validateMessages={requiredFieldValidationMessages(lang)}
            size='large'
            validateTrigger={['onBlur', 'onSubmit']}
            name='registerForm'
            onFinish={onFinish}
            layout='vertical'
            form={form}
        >
            <Form.Item
                hasFeedback
                rules={[{ required: true }]}
                name='firstName'
                label={isPro ? `${First_name[lang]} / ${Business_Name[lang]}` : dictionary.yourFullName[lang]}
            >
                <Input />
            </Form.Item>
            {/* <Form.Item
                    hasFeedback
                    rules={[{ required: true }]}
                    name='lastName'
                    label={`${Last_name[lang]}:`}
                >
                    <Input />
                </Form.Item> */}
            <Form.Item
                hasFeedback
                name="phone"
                // eslint-disable-next-line import/no-named-as-default-member
                label={dictionary.phoneNumber[lang]}
                rules={phoneNumberValidation}
            >
                <Input addonBefore={`+${COUNTRY_CODE}`} />
            </Form.Item>
            {displaySendCode
                ? (
                    <Form.Item>
                        <Button
                            onClick={handleGetCodeClick}
                            loading={formState === 'validationRequestStarted'}
                            htmlType="button"
                            block
                            type="primary"
                        >
                            {dictionary.recieveCode[lang]}
                        </Button>
                    </Form.Item>
                ) : null}
            {formState === 'validationRequestFinished'
                ? (
                    <>
                        <FullWidthFormItem
                            hasFeedback
                            required
                            name='code'
                            label={(
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography.Text>
                                        {dictionary.enterSmsCode[lang]}
                                    </Typography.Text>
                                    <Button style={{ marginLeft: 'auto' }} size='small' onClick={sendCodeAgain} disabled={!sendAgainActive} type='text'>
                                        {dictionary.recieveCodeAgain[lang]}
                                        {NoBreakSpace}
                                        {time > 0 ? formatTime(time) : null}
                                    </Button>
                                </div>
                            )}
                            rules={codeValidationRule}
                        >
                            <Input onChange={handleCodeChange} placeholder="000-000" autoFocus name='code' />
                        </FullWidthFormItem>
                        <Form.Item
                            hasFeedback
                            required
                            name='password'
                            label={dictionary.createPassword[lang]}
                            rules={[{ required: true }, { min: 5, max: 32 }]}
                        >
                            <Input.Password
                                name='password'
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </>
                ): null}
            <Form.Item>
                <TermsOfUseDisclaimer />
            </Form.Item>
            {displaySubmitButton
                ? (
                    <Form.Item>
                        <Button size='large' disabled={isFormSubmitting} htmlType="submit" block type="primary">
                            {dictionary.createAccount[lang]}
                        </Button>
                    </Form.Item>
                ) : null}
        </Form>
    );
};

export default SignupForm;
