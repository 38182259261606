import React from 'react';
import { Review } from '../../../../../shared';
import RatingText from '../RatingText';
import StarRating from '../StarRating';
import { getReviewsData } from '../utils';
import { InlineContainer, ReviewCount } from './style';

interface InlineReviewProps {
    reviews: Review[]
}

const InlineReview = ({ reviews }: InlineReviewProps) => {
    const { average, reviewsCount } = getReviewsData(reviews);

    return (
        <InlineContainer>
            <RatingText small average={average} />
            <StarRating small rating={average} />
            <ReviewCount>
                ({reviewsCount})
            </ReviewCount>
        </InlineContainer>
    );
};

export default InlineReview;
