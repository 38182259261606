import React from 'react';
import { useSelector } from 'react-redux';
import { Review, User } from '../../../../../../shared';
import StarRating from '../../../../details/Review/StarRating';
import { CommentBody, CommentContainer, CommentTitleContainer } from './style';

interface CommentProps {
    review: Review;
    user?: User;
    self?: any;
}

const getUserFullName = ({ firstName, lastName }: Pick<User, 'firstName' | 'lastName'>) => `${firstName || ''} ${lastName || ''}`.trim();

const Comment = ({ review, user,self }: CommentProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lang = useSelector((state: any) => state.settings.lang as 'eng' | 'ru' | 'uz');

    const dateString = new Date(review.createdAt).toLocaleDateString(lang);

    return (
        <CommentContainer>
            <CommentTitleContainer>
                {user
                    ? <span>{getUserFullName(user)}</span>
                    : null
                }
                {!self&&<StarRating rating={review.rate} small />}
            </CommentTitleContainer>
            <CommentBody style={self?{fontWeight:'bold',marginTop:'-4rem'}:{}}>
                { !self?review.comment:review.respond}
            </CommentBody>
            {!self&& <CommentBody>
                {dateString}
            </CommentBody>}
        </CommentContainer>
    );
};

export default Comment;
