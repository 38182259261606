import styled from 'styled-components';

export const RateProTitle = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    display: block;
    margin-bottom: 20px;
`;

export const ReviewWrapper = styled.div`
    max-width: 550px;
    margin: 40px auto 0;
    padding: 48px;
    border: solid 1px #E7E7E7;
    border-radius: 4px;
`;

export const FormWrapper = styled.div`
    display: grid;
    gap: 20px 0;
    margin-top: 40px;
    > :last-child {
        justify-self: flex-end;
    }
`;