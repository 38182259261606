import keyBy from 'lodash/keyBy';
import React, { Fragment, useState } from 'react';
import { Review, User } from '../../../../../shared';
import { Divider } from '../../../details/SharePopup/style';
import { Comment } from './Comment';
import { Pagination } from './Pagination';
import { PaginatedReviewsContainer } from './style';
import {Button,Input, message} from 'antd';
import axios from '../../../../../utils/axios';
import {
    FlagTwoTone
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { loadUser } from 'actions/auth';

interface PaginatedReviewsProps {
    reviews: Review[];
    users: User[];
    front:boolean
}

const PaginatedReviews = ({ reviews, users,front }: PaginatedReviewsProps) => {
    const [page, setPage] = useState(0);
    const [input, setInput] = useState(false);
    const [indexV, setIndex] = useState(-1);
    const[value,setValue]  = useState('');
    const[error,setError]  = useState('');
    const dispatch = useDispatch();
    const totalPages = Math.ceil(reviews.length / 3);
    const currentPageReviews = reviews.slice(page * 3,page * 3 + 3);

    const usersKeyedById = keyBy(users, 'id');

    const handlePrevious = () => {
        const prevPage = page - 1;
        if(prevPage < 0){
            return;
        }
        setPage(prevPage);
    };

    const handleNext = () => {
        const nextPage = page + 1;
        if(nextPage >= totalPages){
            return;
        }
        setPage(nextPage);
    };
    const sendRequest=async(e)=>{
        try{
            await axios.put('/api/v1/pro/rate-update',{
                value: value,
                RateId: e
            });
            dispatch(loadUser());
            window.location.reload();
        }
        catch(err){
            setError('error');
            message.error(error);
        }
    };
    // const deleteRate=async(e)=>{
    //     try{
    //         await axios.put('/api/v1/pro/rate-delete',{
    //             id: e
    //         });
    //         dispatch(loadUser());
    //     }
    //     catch(err){
    //         setError('error');
    //         message.error(error);
    //     }
    //     window.location.reload();
    // };
    return (
        <PaginatedReviewsContainer>

            {currentPageReviews.map((review, index) => (
                <Fragment key={review.id}>
                    { !front ?
                        <FlagTwoTone  style={{textAlign:'right',  fontSize: '18px', cursor:'pointer'}}/>:
                        <div>

                        </div>

                    }
                    <div    style={{marginTop:'-4rem'}}>
                        <Comment

                            review={review}
                            user={usersKeyedById[review.userId]}
                        />
                    </div>
                    {review.respond?
                        <Comment
                            review={review}

                            self={'you'}
                        />:


                        input && indexV==index ?  <div>
                            <Input onChange={(e)=>{setValue(e.target.value);}}/>
                            <br/>
                            <div style={{marginTop:'1rem'}}>  <Button onClick={()=>sendRequest(review.id)}  type="primary" >Respond</Button>   <Button danger onClick={()=>{setInput(false); setIndex(index);}}  >Cancel</Button></div>

                        </div>:
                            <Button style={{width:'max-content',display:front?'none':''}} onClick={()=>{setInput(true); setIndex(index);}}>Respond</Button>
                    }

                    {currentPageReviews.length !== index + 1
                        ? (
                            <Divider />
                        )
                        : null
                    }

                </Fragment>
            ))}
            <Pagination
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                currentPage={page + 1}
                totalPages={totalPages}
            />
        </PaginatedReviewsContainer>
    );
};

export default PaginatedReviews;
