import React from 'react';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import { VerificationDocument } from '../../../../shared';
import CloudinaryImage from '../../../CloudinaryImage';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Button from 'antd/lib/button';
import { getFileExtension } from './utils';
import { FileUploadContainer, UploadPreviewContainer } from './style';

interface UploadedDocumentsProps {
    documents: VerificationDocument[];
}

const UploadedDocuments = ({ documents }: UploadedDocumentsProps) => {
    const download = (url: string, name: string) => {
        const ext = getFileExtension(name);
        const element = document.createElement('a');
        const file = new Blob(
            [
                url
            ],
            { type: ext === 'pdf' ? 'application/pdf' : 'image/*' }
        );
        element.href = URL.createObjectURL(file);
        element.download = name;
        element.click();
    };
    return (
        <FileUploadContainer>
            {documents.map(({ id, url, name, type }) => (
                <UploadPreviewContainer key={id}>
                    {type === 'pdf'
                        ? (
                            <FilePdfOutlined style={{ fontSize: 40 }} />
                        )
                        : <CloudinaryImage objectFit='cover' width={40} height={40} src={url} />
                    }
                    {name}
                    <Button onClick={() => download(url, name)} icon={<DownloadOutlined />} shape='circle'>
                    </Button>
                </UploadPreviewContainer>
            ))}
        </FileUploadContainer>
    );
};

export default UploadedDocuments;
