import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import {HTMLMotionProps, motion} from 'framer-motion';

interface ToggleProps {
    toggled: boolean;
}

export const ToggleIcon = styled(({ toggled, ...rest }: ToggleProps) => <DownOutlined {...rest} />)`
    ${({ toggled }) => `
        transform: rotate(${toggled ? '-180deg' : '0deg'});
        transform: rotate(${toggled ? '-180deg' : '0deg'});
        transition-duration: 0.8s;
        transition-property: transform;
    `}
`;

interface CollapsibleDivProps extends HTMLMotionProps<'div'>{
    showDropdown: boolean;
}

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
        color: #565459 !important;
    }
    &:hover {
        cursor: pointer;
    }
`;

export const CitiesSelectWrapper = styled.div`
    display: grid;
    @media screen and (min-width: 1150px){
        grid-template-columns: 1fr 1fr;
    }
    gap: 24px;
`;

export const MapWrapper = styled.div`
    display: none;
     @media screen and (min-width: 1150px){
        display: block;
    }
`;

export const CitySelectionListWrapper = styled.div`
    display: grid;
    grid-template-columns: 180px auto;
    gap: 30px;
    margin-bottom: 8px;
`;

export const DeselectAll = styled.span`
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
    &:hover{
        text-decoration: none;
        outline: 0;
        color: #40a9ff;
    }
`;

export const SelectionDetailsWrapper = styled.div`
    display: grid;
    gap: 20px;
    width: 100%;
    grid-template-columns: 200px 1fr auto;
    color: #565459 !important;
`;

export const ActionButtonsWrapper = styled.div`
    padding: 4rem 0 2rem;
    display: flex;
    justify-content: space-between;
    button {
        background: var(--color-flickr-blue);
        border: 0;
        color: white;
        cursor: pointer;
    }
    a,
    button.previous {
        background: var(--color-flickr-blue-shade);
        border-radius: 4px;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: var(--color-flickr-blue);
    }
    a,
    button {
        padding: 1.2rem 2.8rem;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        border-radius: 4px;
    }
`;

export const CollapsibleDiv = styled(({ showDropdown, ...rest }: CollapsibleDivProps) => (
    <motion.div
        animate={{
            height: showDropdown ? 'auto' : 0,
        }}
        initial={{ height: 0 }}
        transition={{duration: 0.8}}
        {...rest}
    />
))`
    overflow: hidden;
`;
