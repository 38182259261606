import React from 'react';
import { NoBreakSpace } from '../../../../../../helpers/constants';
import ChevronLeft from '../../../../details/Navigation/PreviousNext/ChevronLeft';
import ChevronRight from '../../../../details/Navigation/PreviousNext/ChevronRight';
import { PaginationContainer, ChevronContainer, PageNumberContainer } from './style';

interface PaginationProps {
    handlePrevious(): void;
    handleNext(): void;
    currentPage: number;
    totalPages: number;
}

const Pagination = ({ currentPage, handleNext, handlePrevious, totalPages }: PaginationProps) => {
    if(totalPages === 0){
        return null;
    }

    return (
        <PaginationContainer>
            <ChevronContainer disabled={currentPage === 1} onClick={handlePrevious}>
                <ChevronLeft />
            </ChevronContainer>
            <PageNumberContainer>
                {currentPage}
                {NoBreakSpace}
                of
                {NoBreakSpace}
                {totalPages}
            </PageNumberContainer>
            <ChevronContainer disabled={currentPage === totalPages} onClick={handleNext}>
                <ChevronRight />
            </ChevronContainer>
        </PaginationContainer>
    );
};

export default Pagination;
