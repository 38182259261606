import {useRouter} from 'next/router';
import Link from 'next/link';
import React, {Children, cloneElement} from 'react';

const ActiveLink = ({children, activeClassName, ...props}) => {
    const {asPath} = useRouter();
    const child = Children.only(children);
    const childClassName = child.props.className || '';

    const className =
    asPath.startsWith(props.href) || asPath === props.as ?
        `${childClassName} ${activeClassName}`.trim() :
        childClassName;

    return (
        <Link {...props}>
            {cloneElement(child, {
                className: className || null,
            })}
        </Link>
    );
};

export default ActiveLink;
