import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Footer from './layout/Footer';


import Navbar from './layout/Navbar';

const LayoutCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  .main-content {
    flex: 1;
    /* offset mobile footer */
    padding-bottom: 66px;
    @media (min-width: 768px) {
      /* reset offset on desktop */
      padding: 0;
    }
  }
 
`;

const Layout = ({ children }) => {
    const settingsState = useSelector((state) => state.settings);
    return (
        <LayoutCss>
            <div className="main-content">
                { settingsState.searchbar ==true ?
                    null:
                    <Navbar lang={settingsState.lang} />}
                {/* <MobileFooter lang={settingsState.lang} /> */}
                {children}
            </div>
            <Footer lang={settingsState.lang} />
        </LayoutCss>
    );
};

export default Layout;
