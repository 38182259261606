import { TFunction } from 'next-i18next';
import { Review } from '../../../../shared';
import { ReviewsMap } from './types';

export const getReviewsData = (reviews: Review[]) => {
    const reviewsMap: ReviewsMap = {};
    let sum = 0;
    reviews&& reviews?.forEach(({ rate }) => {
        const existing = reviewsMap[rate];
        if(existing){
            reviewsMap[rate] = existing + 1;
        } else {
            reviewsMap[rate] = 1;
        }
        sum+= rate;
    });
    const reviewsCount = reviews.length;
    const average: number | null = reviewsCount !== 0
    // ensure correct rounding
        ? Math.round(sum/reviewsCount * 10)/10
    // no reviews
        : null;
    return { reviewsMap, average, reviewsCount };
};

export const getTextFromAverageRating = (average: number, t: TFunction, withNegative = false) => {
    let result: string | null = null;
    if(average === 5){
        result = t('common:superb', { context: 'review rating' });
    } else if(average >= 4.5) {
        result = t('common:veryGood', { context: 'review rating' });
    } else if(average >= 4) {
        result = t('common:good', { context: 'review rating' });
    } else if(average > 3){
        result = t('common:satisfactory', { context: 'review rating' });
    } else if(average === 3){
        result = t('common:average', { context: 'review rating' });
    } else if(average >= 2 && withNegative){
        result = t('common:poor', { context: 'review rating' });
    } else if(average >= 1 && withNegative){
        result = t('common:terrible', { context: 'review rating' });
    }
    return result;
};