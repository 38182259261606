import React from 'react';
import { Review, User } from '../../../../shared';
import { Reviews } from '../../details/Review';
import { Divider } from '../../details/SharePopup/style';
import { SectionContainer } from '../style';
import { PaginatedReviews } from './PaginatedReviews';

interface ReviewsSectionProps {
    reviews: Review[];
    users: User[];
    front:boolean
}

const ReviewsSection = ({ reviews, users,front }: ReviewsSectionProps) => {
    return (
        <SectionContainer style={ front? {padding:'0px',margin:'0px',boxShadow:'none'}:{}}>
            <Reviews reviews={reviews} />
            <Divider />
            <PaginatedReviews
                reviews={reviews}
                users={users}
                front={front}
            />
        </SectionContainer>
    );
};

export default ReviewsSection;
