import styled from 'styled-components';

const RegisterFieldsCss = styled.div`
  .two-fields {
    display: flex;
    justify-content: space-between;
    .input {
      width: 48%;
    }
  }
  .switch {
    position: relative;
    .switchers {
      z-index: 2;
      position: absolute;
      top: -1rem;
      right: 0;
      display: flex;
      align-items: center;
      padding: 1rem 0;
      span.button {
        margin: 0;
        padding: 0;
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: #565459;
        &.active {
          font-weight: 600;
          background: transparent;
          color: ${(props) => props.theme.colors.flickrBlue};
        }
      }
      .switch-input {
        width: 3.6rem;
        height: 0.4rem;
        color: white;
        position: relative;
        margin: 0 0.8rem;
        .line {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          background: #e2e2e9;
          border-radius: 2px;
        }
        .circle {
          position: absolute;
          top: -0.7rem;
          left: 0;
          transition: left 0.5s ease-in-out;
          z-index: 1;
          width: 1.6rem;
          height: 1.6rem;
          background: #ffffff;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1),
            0px 1px 3px rgba(0, 0, 0, 0.2);
          border-radius: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &.right {
            left: 80%;
          }
          span {
            width: 0.8rem;
            height: 0.8rem;
            background: var(--color-flickr-blue);
            border-radius: 3rem;
            display: inline-block;
          }
        }
      }
    }
  }
  .signup-form-container {
    background: #ffffff;
    box-shadow: 0px 63px 50px rgba(67, 67, 68, 0.0705882);
    border-radius: 4px;
    padding: 3.2rem;

    .header {
      margin: auto;
      h1 {
        font-weight: 700;
        font-size: 2.2rem;
        color: var(--color-jet);
      }
      button {
        cursor: pointer;
        padding: 0.8rem 1.2rem;
        margin: 0;
        color: #B5B5BE;
        background: transparent;
        border: 0;
        font-weight: 600;
        font-size: 1.3rem;
        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
        &.active {
          background-color: ${(props) => props.theme.colors.flickrBlueShade};
          color: var(--color-flickr-blue);
        }
      }
    }
    span.error {
      display: inline-block;
      background: #fff0f1;
      border-radius: 4px;
      width: 100%;
      text-align: center;
      padding: 1.2rem 0;
      font-weight: 600;
      font-size: 15px;
      color: var(--color-red);
      margin: 2.4rem 0;
    }
    button {
      cursor: pointer;
      padding: 1.2rem 1.6rem;
      margin: 0;
      color: #B5B5BE;
      background: transparent;
      border: 0;
      font-weight: 600;
      font-size: 1.5rem;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
      &.active {
        background-color: ${(props) => props.theme.colors.flickrBlueShade};
        color: var(--color-flickr-blue);
      }
    }
    .remember-me {
      font-size: 1.5rem;
      font-weight: 600;
      input {
        margin-right: 0.6rem;
      }
    }
    .agreements {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 15px;
      color: #565459;
      margin-top: -1.2rem;
      .row {
        display: flex;
        span {
          margin: 0 0.5rem;
        }
        a {
          color: var(--color-flickr-blue);
        }
      }
    }
    .register-button {
      background: var(--color-flickr-blue) !important;
      width: 100%;
      margin-top: 2.6rem !important;
      display: inline-block;
      color: white;
      border-radius: 4px;
      outline: none;
    }
  }
  h4 {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: #858a8f;
    margin-right: 1.2rem;
  }
  .forgot-password {
    color: var(--color-flickr-blue);
    font-weight: 600;
    font-size: 1.5rem;
    padding: 0 !important;
    margin: 0;
  }
  @media (min-width: 768px) {
    .signup-form-container {
      width: 56rem;
      margin: auto;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
          font-size: 3.4rem;
        }
        button {
          padding: 1.2rem 1.6rem;
          font-size: 1.5rem;
        }
      }
    }
  }
`;

export default RegisterFieldsCss;