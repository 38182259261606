import { Row, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { NoBreakSpace } from '../../../helpers/constants';
import { By_clicking_Create_Account_you_agree_to_the, Terms_of_Use, and, Privacy_Policy } from '../../../helpers/dictionary';

const TermsOfUseDisclaimer = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lang = useSelector((state: any) => state.settings.lang as string);
    return (
        <>
            <Row justify='center'>
                <Typography.Text style={{ fontSize: 12 }}>
                    {By_clicking_Create_Account_you_agree_to_the[lang]}
                </Typography.Text>
            </Row>
            <Row justify='center'>
                <Typography.Text style={{ fontSize: 12 }}>
                    <Typography.Link>
                        {Terms_of_Use[lang]}
                    </Typography.Link>
                    {NoBreakSpace}{and[lang]}{NoBreakSpace}
                    <Typography.Link>
                        {Privacy_Policy[lang]}
                    </Typography.Link>
                </Typography.Text>
            </Row>
        </>
    );
};

export default TermsOfUseDisclaimer;
