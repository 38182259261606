import { setCookie } from './cookie';
import { CHANGE_LANG } from '../actions/types';

export const handleLanguageChange = (e, dispatch) => {
    setCookie('NEXT_LOCALE', e.target.dataset.lang);

    dispatch({
        type: CHANGE_LANG,
        payload: e.target.dataset.lang,
    });
};
