import styled from 'styled-components';

interface ContainerProps {
    mWidth?: string;
}

const Container = styled.div<ContainerProps>`
  padding: 0 1.6rem;
  width: 100%;
  margin: auto;
  position: relative;
  @media (min-width: 768px) {
    padding: 0 3.2rem;
  }
  @media (min-width: 1024px) {
    padding: 0;
    max-width: 946px;
  }
  @media (min-width: 1600px) {
    max-width: ${(props) => (props.mWidth ? props.mWidth : '1300px')};
  }
`;
interface ContainerWithBackgroundProps {
    padding?: boolean;
}

export const ContainerWithBackground = styled.div<ContainerWithBackgroundProps>`
   background-color: #fafafa;
   ${({ padding }) => padding ? `padding: 0 16px;`: ``}
`;

export default Container;
