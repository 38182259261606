import styled from 'styled-components';

export const SectionContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 0.5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 24px 20px;
    max-width: 60rem;
    margin: 30px auto;
    display: grid;
    gap: 30px 0;
`;
export const SectionTitle = styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
`;