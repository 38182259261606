import React from 'react';
import styled from 'styled-components';

const InputCss = styled.div`
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  input {
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    padding: 1.4rem 0 1.4rem 0.8rem;
    border-radius: 4px;
    border: 1.5px solid #e2e2e9;
  }
  svg {
    pointer-events: none;
    position: absolute;
    left: 1rem;
    fill: #b5b5be;
    top: 1.2rem;
    width: 2rem;
  }
`;

const Input = ({
    placeholder = '',
    type = 'text',
    className,
    value,
    handleChange,
    onClick,
    readOnly,
    fullWidth,
}) => {
    if (readOnly) {
        return (
            <InputCss onClick={onClick} className={className}>
                <input value={value} readOnly />
            </InputCss>
        );
    }

    return (
        <InputCss fullWidth={fullWidth} onClick={onClick} className={className}>
            <input
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                type={type}
            />
        </InputCss>
    );
};

export default Input;
