import styled from 'styled-components';
import { SmallText } from '../../style';

export const ReviewsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-areas: "title title title"
                          "left  divider right";
    gap: 16px 40px;
    max-width: 480px;
    min-width: 240px;
`;

export const ReviewTitle = styled.h4`
    grid-area: title;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
`;

export const Divider = styled.div`
    grid-area: divider;
    background-color: #DEDEDE;
    width: 1px;
`;

export const LeftColumn = styled.div`
    grid-area: left;
    display: grid;
    gap: 20px;
`;

export const RightColumn = styled.div`
    grid-area: right;
`;

export const RatingTextContainer = styled.span<{ small?: boolean }>`
    color: var(--color-green);
    ${({ small }) => small
        ? `
            font-size: 14px;
            line-height: 12px;
        `
        : `
            font-size: 18px;
            line-height: 20px;
        `}
    font-weight: 600;
`;

export const NoReviewsText = styled(RatingTextContainer)`
    color: var(--color-grey);
`;

export const StarsContainer = styled.div<{ small?: boolean }>`
    unicode-bidi: bidi-override;
    color: #E2E2E9;
    ${({ small }) => small
        ? `
            width: 48px;
            font-size: 12px;
            height: 12px;
            line-height: 12px;
        `
        : `
            width: 96px;
            font-size: 24px;
            height: 24px;
            line-height: 24px;
        `}
    position: relative;
    padding: 0;
`;

export const StarsTop = styled.div<{ rating: number | null }>`
    color: var(--color-green);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
    ${({ rating }) => `width: ${rating ? `calc(${rating}/4 * 100%)` : '0'};`}
`;
export const StarsBottom = styled.div`
    padding: 0;
    display: block;
    z-index: 0;
`;

export const ReviewsCount = styled.span`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #565459;
`;

export const RatingRowContainer = styled.div`
    display: grid;
    grid-template-columns: 28px auto 28px;
    gap: 10px;
    align-items: center;
`;

export const ProgressBar = styled.div<{ rating: number | null}>`
    display: block;
    width: 100%;
    background-color: #E8EBF3;
    height: 6px;
    border-radius: 8px;
    position: relative;
    :before {
        content: '';
        display: block;
        width: 100%;
        height: 6px;
        background-color: var(--color-green);
        border-radius: 8px;
        ${({ rating }) => `width: ${rating ? `${rating}%` : '0'};`}
    }
`;

export const NumberWithStar = styled(SmallText)`
    :after {
        content: '★';
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
        color: #C3C6D1;
    }
`;
