import React from 'react';
import styled from 'styled-components';

const FlexCss = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  flex-direction: ${(props) => props.direction};

  @media (max-width: 450px) {
    height: ${(props) => (props.mobileHeight ? props.mobileHeight : 'auto')};
  }

  @media (max-width: 768px) {
    height: ${(props) =>
        props.smallTabletHeight ? props.smallTabletHeight : 'auto'};
  }
`;

const Flex = ({
    children,
    justify = 'normal',
    align = 'normal',
    direction = 'row',
    mobileHeight,
    smallTabletHeight,
}) => {
    return (
        <FlexCss
            mobileHeight={mobileHeight}
            smallTabletHeight={smallTabletHeight}
            justify={justify}
            align={align}
            direction={direction}
        >
            {children}
        </FlexCss>
    );
};

export default Flex;
