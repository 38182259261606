import React from 'react';
import * as PropTypes from 'prop-types';
import Head from 'next/head';

function SEO({ description, meta, title,keywords }) {
    const titleAlt = 'TOPX.UZ';

    return (
        <Head>
            <title>
                {title} - {titleAlt}
            </title>
            <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
            <meta name="keywords" content={keywords}/>

            <meta
                name="Description"
                content={
                    description
                        ? description
                        : 'Find the perfect professional for you at Topx service'
                }
            />
        </Head>
    // <Helmet
    //   htmlAttributes={{
    //     lang,
    //   }}
    //   title={title}
    //   titleTemplate={`%s - ${titleAlt}`}
    //   meta={[
    //     {
    //       name: `description`,
    //       content: metaDescription,
    //     },
    //     {
    //       property: `og:title`,
    //       content: title,
    //     },
    //     {
    //       property: `og:description`,
    //       content: metaDescription,
    //     },
    //     {
    //       property: `og:type`,
    //       content: `website`,
    //     },
    //     {
    //       name: `twitter:card`,
    //       content: `summary`,
    //     },
    //     {
    //       name: `twitter:creator`,
    //       content: '@topx',
    //     },
    //     {
    //       name: `twitter:title`,
    //       content: title,
    //     },
    //     {
    //       name: `twitter:description`,
    //       content: metaDescription,
    //     },
    //   ].concat(meta)}
    // />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    keywords:  PropTypes.string,
};

export default SEO;
