import React from 'react';
import { useTranslation } from 'next-i18next';
import RatingText from './RatingText';
import StarRating from './StarRating';
import { LeftColumn, ReviewsCount } from './style';

interface ReviewsSummaryProps {
    average: number;
    reviewsCount: number;
}

const ReviewsSummary = ({ average, reviewsCount }: ReviewsSummaryProps) => {
    const { t } = useTranslation();
    return (
        <LeftColumn>
            <RatingText average={average} />
            <StarRating rating={average} />
            <ReviewsCount>
                {t('profile:{{count}} reviews', { count: reviewsCount })}
            </ReviewsCount>
        </LeftColumn>
    );
};

export default ReviewsSummary;
