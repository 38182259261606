import React, { useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import Image from '../../../Image';
import axios from '../../../../utils/axios';
import Progress from 'antd/lib/progress';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { DropZoneContainer, DropZoneHintContainer, FileUploadContainer, UploadIcon, SubmitButton, UploadPreviewContainer, IconButton } from './style';
import { getFileExtension } from './utils';
import { VerificationDocument } from '../../../../shared';
import { useTranslation } from 'react-i18next';

type UploadState = 'prestine' | 'uploadStarted' | 'uploadFinished' | 'uploadFailed';

interface FileUploadProps {
    setDocuments(documents: VerificationDocument[]):void;
}

const FileUpload = ({ setDocuments }: FileUploadProps) => {
    const { t } = useTranslation('profile');
    const [files, setFiles] = useState<File[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadState, setUploadState] = useState<UploadState>('prestine');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDrop: DropzoneOptions['onDrop'] = (acceptedFiles, _fileRejections) => {
        setFiles([...files, ...acceptedFiles]);
    };

    const submitHandler = async () => {
        setUploadState('uploadStarted');
        const formData = new FormData();
        for (const file of files){
            formData.append('files', file);
        }

        try {
            const { data: uploadedFiles} = await axios.post<VerificationDocument[]>(
                '/api/v3/pros/verificationDocumets',
                formData, {
                    onUploadProgress: ({ loaded, total }) => {
                        const progress = Math.round((loaded * 100) / total);
                        setUploadProgress(progress);
                    }
                }
            );

            setUploadState('uploadFinished');
            setDocuments(uploadedFiles);
            setFiles([]);
        } catch (error) {
            setUploadState('uploadFailed');
            setUploadProgress(0);
        }

    };

    const handleFileRemove = (index: number) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['image/*', 'application/pdf'],
        multiple: true,
    });

    const hasFilesToUpload = files.length > 0;
    const shouldShowProgress = hasFilesToUpload && (uploadState === 'uploadFinished' || uploadState === 'uploadStarted');

    return (
        <FileUploadContainer>
            <DropZoneContainer {...getRootProps()}>
                <DropZoneHintContainer>
                    <UploadIcon />
                    <span style={{ display: 'block' }}>
                        {t('dragAndDropCta')}
                    </span>
                    <span>
                        {t('verificationDocumentsAllowedFormats')}
                    </span>
                </DropZoneHintContainer>
                <input type='file' multiple {...getInputProps()} />
            </DropZoneContainer>
            {shouldShowProgress
                ? <Progress percent={uploadProgress} />
                : null
            }
            {files.map((file, index) =>(
                <UploadPreviewContainer key={file.name}>
                    {getFileExtension(file.name) !== 'pdf'
                        ? <Image objectFit='cover' width={40} height={40} src={URL.createObjectURL(file)}/>
                        : <FilePdfOutlined style={{ fontSize: 40 }} />
                    }
                    <span key={file.name}>{file.name}</span>
                    <IconButton danger shape='circle' icon={<CloseOutlined />} onClick={() => handleFileRemove(index)} />
                </UploadPreviewContainer>
            ))}
            <SubmitButton
                loading={uploadState === 'uploadStarted'}
                disabled={!hasFilesToUpload}
                onClick={submitHandler}
                type='primary'
            >
                {t('submitVerificationDocuments')}
            </SubmitButton>
        </FileUploadContainer>
    );
};

export default FileUpload;
