import styled from 'styled-components';
import Button, { ButtonProps } from 'antd/lib/button/button';

export const Semibold15Title = styled.h4`
    color: #000000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
`;

export const CaptionText = styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #565459;
    margin: 0;
`;

export const SpacedContainer = styled.div`
    display: grid;
    gap: 10px;
`;

export const SmallText = styled.span`
    color: #565459;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
`;

export const TopContainer = styled.div`
    padding: 0 15px;
    @media screen and (min-width: 768px) {
        padding: 0;
    }
    max-width: 680px;
    margin: 0 auto 40px auto;
    display: grid;
    gap: 35px;
`;

export const HorizontalDivider = styled.div`
    height: 1px;
    background-color: #ECECEC;
    width: 100%;
    margin: 30px 0;
`;

export const BottomContainer = styled.div`
    padding: 0 15px;
    max-width: 680px;
    margin: 40px auto;
    @media screen and (min-width: 768px) {
        padding: 0;
    }
`;

export const StyledButton = styled(({ wide, ...props }: ButtonProps & { wide?: boolean }) => <Button {...props} />)`
    @media screen and (min-width: 768px) {
        height: 48px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 4px;
        ${({ wide }) => wide
        ? `
                padding-left: 50px;
                padding-right: 50px;
            `
        : ``}
    }
`;