import { Button, Checkbox, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import dictionary, { errorCodes, Forgot_password, Log_in, Remember_me } from '../../../helpers/dictionary';
import { COUNTRY_CODE } from '../constants';
import { phoneNumberValidation, requiredFieldValidationMessages } from '../validation';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { ApiErrorResponse } from '../../../shared';

interface FormValues {
    phone: number;
    countryCode: string;
    password: string;
    remember: boolean;
}

interface LoginFormProps {
    onSubmit(args: Omit<FormValues, 'remember'>): Promise<void>;
}

const
    LoginForm = ({ onSubmit }: LoginFormProps) => {
        const [form] = Form.useForm<FormValues>();
        const [isFormSubmitting, setIsFormSubmitting] = useState(false);
        const router = useRouter();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lang = useSelector((state: any) => state.settings.lang as string);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error = useSelector((state: any)=>state.auth.errors.length>0&&state.auth.errors[0] as string);

        const onFinish = async (values: FormValues) => {
            setIsFormSubmitting(true);
            const { password, phone } = values;
            try {
                await onSubmit({
                    password,
                    phone,
                    countryCode: COUNTRY_CODE,
                });
            } catch (error) {
                const { response: { data: { errors } } } = error as ApiErrorResponse;
                const formErrors = errors.map(({ param, errorCode, msg }) => {
                    const errorMessage = errorCodes?.[errorCode]?.[lang] || msg;
                    return {
                        name: param,
                        errors: [errorMessage],
                    };

                });
                form.setFields(formErrors);
                setIsFormSubmitting(false);
            }
            setIsFormSubmitting(false);

        };

        const handleForgotPasswordClick = () => {
            router.push('/reset-password');
        };

        return (
            <Form
                size='large'
                validateMessages={requiredFieldValidationMessages(lang)}
                validateTrigger={['onBlur', 'onSubmit']}
                name='registerForm'
                onFinish={onFinish}
                layout='vertical'
                form={form}
            >
                <Form.Item
                    hasFeedback
                    name="phone"
                    validateStatus={error?'error':null}
                    // eslint-disable-next-line import/no-named-as-default-member
                    label={dictionary.phoneNumber[lang]}
                    rules={phoneNumberValidation}
                >
                    <Input addonBefore={`+${COUNTRY_CODE}`} />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    required
                    name='password'
                    validateStatus={error?'error':null}
                    help={error?error:null}
                    label={dictionary.password[lang]}
                    rules={[{ required: true }]}
                >
                    <Input.Password
                        name='password'
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item>
                    <Row justify='space-between' align='middle'>
                        <Form.Item name="remember" noStyle valuePropName="remember">
                            <Checkbox>{Remember_me[lang]}</Checkbox>
                        </Form.Item>
                        <Button size='small' type='text' onClick={handleForgotPasswordClick}>
                            {Forgot_password[lang]}
                        </Button>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Button size='large' disabled={isFormSubmitting} htmlType="submit" block type="primary">
                        {Log_in[lang]}
                    </Button>
                </Form.Item>
            </Form>
        );
    };

export default LoginForm;
