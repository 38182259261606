
// Consider moving it to shared space
export const stringAsNumberValidator = (_: unknown, value: string) => {
    if (!value) {
        return Promise.reject();
    }
    const number = Number(value);
    if (isNaN(number)) {
        return Promise.reject('Has to be a number.');
    }
    return Promise.resolve();
};

export const formatTime = (time: number) => {
    if(time < 10) {
        return `00:0${time}`;
    }
    if(time < 60) {
        return `00:${time}`;
    }

    const remainder = time % 60;

    const minutes = (time - remainder) / 60;
    const formattedReminder = remainder < 10 ? `0${remainder}` : remainder;

    return `${minutes}:${formattedReminder}`;
};