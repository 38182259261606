import styled from 'styled-components';
import BaseModal from 'react-modal';

export const ModalHead = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: title action;
`;

export const CloseContainer = styled.div`
    grid-area: action;
    :hover {
        cursor: pointer;
    }
`;

export const Modal = styled(BaseModal)`
    &&.ReactModal__Content {
        width: 330px;
        background: white;
        box-shadow: 0px 63px 50px rgba(67, 67, 68, 0.0705882);
        padding: 24px 20px;
    }
`;

export const ModalTitle = styled.h3`
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
`;

export const ShareLink = styled.a`
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    display: grid;
    grid-template-columns: 16px auto;
    gap: 0 10px;
    align-items: center;
    :hover, :focus {
        color: #000000;
    }
`;

export const Divider = styled.div`
    background-color: #E2E2E9;
    width: 100%;
    height: 1px;;
`;

export const LinksContainer = styled.div`
    display: grid;
    gap: 10px 0;
    margin: 30px 0;
`;