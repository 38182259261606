import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useRedirectUnauthorized = ({
    allowPros = true,
    allowClients = true,
    allowUnauthenticated = true,
}) => {
    const router = useRouter();
    const { user, pro, loading } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!loading) {
            if (!user && !allowUnauthenticated) {
                return router.replace('/login');
            } else {
                if (user && user.status === 'pro' && pro && pro.id && !allowPros) {
                    return router.replace('/pro/leads');
                }

                if (user && user.status === 'customer' && !allowClients) {
                    return router.replace('/customer/activity');
                }
            }
        }
    }, []);
};
