import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Star, StarsContainer, RatingText, RatingContainer } from './style';

interface StarRatingProps {
    rating?: number;
    small?: boolean;
    handleClick(rating: number): void;
}

const stars = Array(5).fill(0);

const StarRating = ({ rating = 0, small, handleClick }: StarRatingProps) => {
    const { t } = useTranslation();
    const [hoverRating, setHoverRating] = useState(0);
    const onMouseEnter = (i: number) => setHoverRating(i);
    const onMouseLeave = () => setHoverRating(0);

    const reviewMap = {
        5: t('common:superb', { context: 'review rating' }),
        4: t('common:good', { context: 'review rating' }),
        3: t('common:average', { context: 'review rating' }),
        2: t('common:poor', { context: 'review rating' }),
        1: t('common:terrible', { context: 'review rating' }),
    };

    return (
        <RatingContainer>
            <RatingText>{rating ? reviewMap[rating]: ''}</RatingText>
            <StarsContainer small={small} >
                {stars.map((_v, index) => (
                    <Star
                        highlight={rating >= index + 1 || hoverRating >= index + 1}
                        key={`${index}_bottom`}
                        onMouseEnter={() => onMouseEnter(index+1)}
                        onMouseLeave={onMouseLeave}
                        onClick={() => handleClick(index+1)}
                    >
                        ★
                    </Star>
                ))}
            </StarsContainer>
        </RatingContainer>
    );
};

export default StarRating;
