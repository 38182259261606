import styled from 'styled-components';

export const InlineContainer = styled.div`
    display: grid;
    position: relative;
    top: -15px;
    gap: 10px;
    grid-auto-flow: column dense;
    justify-content: start;
`;

export const ReviewCount = styled.span`
    font-size: 14px;
    line-height: 12px;
    color: #7A7A7A;
    margin-left: 12px;
`;