import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SWITCH_TO_PRO, USER_LOADED } from '../../../actions/types';
import { Back, Next } from '../../../helpers/dictionary';
import axios from '../../../utils/axios';
import { ActionButtonsWrapper } from './style';


interface ActionButtonsProps {
    currentStep: number;
    previousStep(): void;
    totalSteps: number;
    goNext(): void;
    switchToPro?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    answers: any[];
    districts: number[];
    switchToProBack?: boolean;
    disableNext?: boolean;
}

const ActionButtons = ({
    currentStep,
    previousStep,
    totalSteps,
    goNext,
    switchToPro,
    answers,
    switchToProBack,
    districts,
    disableNext,
}: ActionButtonsProps) => {
    const router = useRouter();
    const lang = useSelector((state: { settings: { lang: 'eng' | 'uz' | 'ru' } }) => state.settings.lang);
    const dispatch = useDispatch();

    const onSwitchToPro = async () => {
        await axios.post('/api/v1/pro/set-pro', {
            service: router.query.id,
            answers,
            districts,
        });

        dispatch({
            type: SWITCH_TO_PRO,
        });

        const proData = await axios.get(`/api/v1/pro`, {
            withCredentials: true,
        });

        const payload = {
            user: proData.data,
            pro: proData.data.pro,
        };

        dispatch({
            type: USER_LOADED,
            payload,
        });

        await router.replace('/pro/profile');
    };

    return (
        <ActionButtonsWrapper>
            {currentStep === 1 ? (
                <Link
                    href={switchToProBack ? '/join-as-pro?switch=true' : '/join-as-pro'}
                >
                    <a>{Back[lang]}</a>
                </Link>
            ) : (
                <button className="previous" onClick={() => previousStep()}>
                    {Back[lang]}
                </button>
            )}
            {switchToPro && (
                <button disabled={disableNext} className="next" onClick={onSwitchToPro}>
          Switch to Pro
                </button>
            )}
            {currentStep !== totalSteps && (
                <button disabled={disableNext} className="next" onClick={goNext}>
                    {Next[lang]}
                </button>
            )}
        </ActionButtonsWrapper>
    );
};

export default ActionButtons;
