import React from 'react';
import { useTranslation } from 'next-i18next';
import { NoBreakSpace } from '../../../../helpers/constants';
import { NoReviewsText, RatingTextContainer } from './style';
import { getTextFromAverageRating } from './utils';

interface RatingTextProps {
    average: number | null;
    small?: boolean;
}

const RatingText = ({ average, small }: RatingTextProps) => {
    const { t } = useTranslation();

    if(!average){
        return (
            <NoReviewsText small={small}>
                {t('profile:No reviews')}
            </NoReviewsText>
        );
    }

    const text = getTextFromAverageRating(average, t);
    return (
        <RatingTextContainer small={small}>
            {text}
            {NoBreakSpace}
            {average.toFixed(1)}
        </RatingTextContainer>
    );
};

export default RatingText;
