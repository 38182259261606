import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import React from 'react';
import { useSelector } from 'react-redux';
import { allDistricts, NoDistrict, selectedDistricts as selectedDistrictsT } from '../../../helpers/dictionary';
import { CitySelectionListWrapper } from './style';
import { City, District } from './types';

interface SelectedCityListProps {
    cities: City[];
    selectedDistricts: District[];
}

const SelectedCityList = ({ cities, selectedDistricts }: SelectedCityListProps) => {
    const lang = useSelector((state: { settings: { lang: 'eng' | 'uz' | 'ru' } }) => state.settings.lang);
    if(selectedDistricts.length === 0){
        return <p>{NoDistrict[lang]}</p>;
    }
    const keyedCitiesByCityId = keyBy(cities, 'id');
    const selectedCities = groupBy(selectedDistricts, 'cityId');
    const selectedCityIds = keys(selectedCities);
    return selectedCityIds.map(cityId => {
        const selectionLength = selectedCities[cityId].length;
        const availableCitiesLength = keyedCitiesByCityId[cityId].districts.length;
        const allSelected = selectionLength === availableCitiesLength;
        const city = keyedCitiesByCityId[cityId];
        return (
            <CitySelectionListWrapper key={cityId}>
                <p style={{ fontWeight: 600 }}>{city[lang]}</p>
                <p>
                    {(allSelected ? allDistricts : selectedDistrictsT)[lang]}
                </p>
            </CitySelectionListWrapper>
        );
    });
};

export default SelectedCityList;
