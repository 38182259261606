import styled from 'styled-components';

export const CommentTitleContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 0 8px;
    justify-self: start;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-jet);
`;

export const CommentContainer = styled.div`
    display: grid;
    gap: 24px 0;
`;

export const CommentBody = styled.p`
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #666666;
`;

export const DateContainer = styled.span`
    color: #999999;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`;
