import React, { memo } from 'react';
import Link from 'next/link';
import Container from '../styles/Container';
import {
    ParaF,
    ProfF,
    Whatever_work_you_do,
    Whatever_work_you_do2,
} from '../../helpers/dictionary';
import { BeAProCss } from '../styles/homepage';

const BeAPro = ({ lang }) => {
    return (
        <BeAProCss>
            <Container className="cs" style={{margin:'unset'}}>
                <div className="css-1"style={{width:'100%',height:'280px'}}>
                    <div className="rowB" style={{ marginTop:'87px'}}>
                        <span>
                            <h2 style={{fontSize:'28px',fontWeight:700}}>{ProfF[lang]}</h2>
                            <p style={{fontSize:'15px',fontWeight:600}}>{Whatever_work_you_do[lang]}<br/>{Whatever_work_you_do2[lang]}</p>
                        </span>
                        <Link href="/join-as-pro">
                            <div>
                                <a  className="anch" style={{display:'list-item', textAlign:'center', marginTop:'8px', borderRadius:'4px',height:'44px',fontSize:'15px', fontWeight:'600',padding:'12px  28px  12px 28px',background:'var(--color-french-violet)',color:'white'}}>{ParaF[lang]}</a>
                            </div>
                        </Link>
                    </div>
                </div>
            </Container>
        </BeAProCss>
    );
};

export default memo(BeAPro);
