import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const SliderCss = styled.div`
  width: 100%;
  .slick-dots {
    position: absolute;
    text-align: right;
    top: 2rem;
    right: 2rem;
    width: auto;
    height: auto;
    li {
      margin: 0;
    }
  }
  .slider-item-css {
      height: 413px;
    .arrows {
      margin-bottom: 0.8rem;
      button {
        background: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        margin-right: 1.2rem;
        outline: none;
      }
    }
    position: relative;
    .info {
      position: absolute;
      bottom: 2rem;
      padding: 0 2.4rem;
      z-index: 2;
      h2 {
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        margin: 0;
      }
      h3 {
        font-weight: 600;
        font-size: 15px;
        color: var(--color-flickr-blue-shade);
        margin: 0;
        margin-bottom: 0.2rem;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }
  @media (min-width: 768px) {
    width: 95%;
    .slider-item-css .info h2 {
      font-size: 22px;
    }
  }
`;

export default class PreviousNextMethods extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.state = {
            slideN: 1,
        };
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        const interv = setInterval(() => {
            if (this.slider) {
                if (this.state.slideN === 1) {
                    this.slider.slickGoTo(1);
                    this.setState({
                        slideN: 2,
                    });
                } else if (this.state.slideN === 2) {
                    this.slider.slickGoTo(2);
                    this.setState({
                        slideN: 3,
                    });
                } else if (this.state.slideN === 3) {
                    this.slider.slickGoTo(3);
                    this.setState({
                        slideN: 4,
                    });
                } else if (this.state.slideN === 4) {
                    clearInterval(interv);
                }
            }
        }, 2600);
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <SliderCss>
                <Slider ref={(c) => (this.slider = c)} {...settings} {...settings}>
                    <div style={{width:'342px',height:'409px'}} className="slider-item-css">
                        <img style={{objectFit:'cover',borderRadius:'4px',width:'358px',height:'409px'}} src="/assets/images/wood.png" />
                        <div className="info">
                            <div className="arrows">
                                <button onClick={this.previous}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.75">
                                            <path d="M1 12L5 8V16L1 12Z" fill="white" />
                                            <path d="M4 11H23V13H4V11Z" fill="white" />
                                        </g>
                                    </svg>
                                </button>
                                <button onClick={this.next}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.75">
                                            <path d="M23 12L19 8V16L23 12Z" fill="white" />
                                            <path d="M20 11H1V13H20V11Z" fill="white" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'342px',height:'409px'}} className="slider-item-css">
                        <img style={{objectFit:'cover',borderRadius:'4px',width:'358px',height:'409px'}} src="/assets/images/join-pro-2.webp" />
                        <div className="info">
                            <div className="arrows">
                                <button onClick={this.previous}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.75">
                                            <path d="M1 12L5 8V16L1 12Z" fill="white" />
                                            <path d="M4 11H23V13H4V11Z" fill="white" />
                                        </g>
                                    </svg>
                                </button>
                                <button onClick={this.next}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.75">
                                            <path d="M23 12L19 8V16L23 12Z" fill="white" />
                                            <path d="M20 11H1V13H20V11Z" fill="white" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'342px',height:'409px'}} className="slider-item-css">
                        <img style={{objectFit:'cover',borderRadius:'4px',width:'358px',height:'409px'}} src="/assets/images/job-girl.jpg" />
                        <div className="info">
                            <div className="arrows">
                                <button onClick={this.previous}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.75">
                                            <path d="M1 12L5 8V16L1 12Z" fill="white" />
                                            <path d="M4 11H23V13H4V11Z" fill="white" />
                                        </g>
                                    </svg>
                                </button>
                                <button onClick={this.next}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.75">
                                            <path d="M23 12L19 8V16L23 12Z" fill="white" />
                                            <path d="M20 11H1V13H20V11Z" fill="white" />
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </Slider>
            </SliderCss>
        );
    }
}
