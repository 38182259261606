import React, { useState } from 'react';
import CitySelect from './CitySelect';
import ActionButtons from './ActionButtons';
import { City } from './types';

interface SwitchToProCitySelectProps {
    cities: City[];
    nextStep(): void;
    previousStep(): void;
    currentStep: number;
    totalSteps: number;
    switchToPro?: boolean;
    answers: unknown[];
    getDistricts?(districts: number[]): void;
}

const SwitchToProCitySelect = ({ cities, currentStep, previousStep, getDistricts, switchToPro, totalSteps, nextStep, answers }: SwitchToProCitySelectProps) => {
    const [districts, setDistricts] = useState<number[]>([]);
    const [error, setError] = useState('');

    const setData = (data: number[]) => {
        setDistricts(data);
        if(getDistricts){
            getDistricts(data);
        }
    };

    const goNext = () => {
        if(districts.length === 0){
            setError('Please, select at least 1 district');
        } else {
            setError('');
            nextStep();
        }
    };
    return (
        <div>
            {error ? <span className='error'>{error}</span> : null}
            <CitySelect cities={cities} getData={setData} />
            <ActionButtons
                currentStep={currentStep}
                previousStep={previousStep}
                totalSteps={totalSteps}
                goNext={goNext}
                answers={answers}
                districts={districts}
                switchToPro={switchToPro}
            />
        </div>
    );
};

export default SwitchToProCitySelect;
