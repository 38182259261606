import Link from 'next/link';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useRef, useState, memo } from 'react';
import {
    Find_the_perfect_professional_for_you,
    Search,
    Search_service,
    Tashkent,
} from '../../helpers/dictionary';
import useGetSearchFunction from '../../hooks/useGetSearchResults';
import SearchIcon from '../../svgs/search.svg';
import axios from '../../utils/axios';
// import { getBackImages } from '../../utils/getImages';
import { HeaderCss } from '../styles/homepage';
import Input from '../styles/Input';

import SliderComponent from './sliderHome';
import { useDispatch } from 'react-redux';
import { TOGGLE_SEARCHBAR } from 'actions/types';
function useOutsideAlerter(ref, setShowResults) {
    useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowResults(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

const Header = ({ lang, popular,func }) => {
    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });
    const router = useRouter();

    // const [backImage, _] = useState(getBackImages());
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    const [showResults, setShowResults] = useState(false);
    const [recommendations, setRecommendations] = useState(null);
    const { searchResults } = useGetSearchFunction(query, lang,false,true);



    const searchResultsRef = useRef(null);
    useOutsideAlerter(searchResultsRef, setShowResults);

    const sendSearchQuery = (e) => {
        e.preventDefault();

        router.replace(`/service/${searchResults[0].id}`);
    };

    const fetchRecommendations = async () => {
        const { data } = await axios.get(`/api/v1/service/recommended`);

        setRecommendations(data);
    };

    useEffect(() => {
        const source = axios.CancelToken.source();
        fetchRecommendations();
        return () => source.cancel();
    }, []);
    const displayResults = () => {
        setShowResults(true);
    };
    const displayResultsMob = () => {
        setShowResults(true);
        dispatch({
            type: TOGGLE_SEARCHBAR,
            payload: true
        });
        func(true);
    };


    return (
        <HeaderCss className="headerCs" >

            {
                (typeof window !== 'undefined' && window.innerWidth<768)?  <div className="header-container row">
                    <div className="col" style={{width:'100%'}}>
                        <div className="header-form">

                            <form className="formS"style={{background: '#F9F9FA',padding:'14px',marginBottom:'50px'}} onSubmit={sendSearchQuery}>
                                <div className="input-and-result">{
                                    isTabletOrMobile?
                                        <Input
                                            className="search-input"
                                            placeholder={Search_service[lang]}
                                            value={query}
                                            handleChange={setQuery}
                                            onClick={displayResultsMob}
                                        />

                                        :<Input
                                            className="search-input"
                                            placeholder={Search_service[lang]}
                                            value={query}
                                            handleChange={setQuery}
                                            onClick={displayResults}
                                        />}
                                {showResults && (
                                    <ul ref={searchResultsRef} className="search-results">
                                        {searchResults
                                            ? searchResults.slice(0, 5).map((result, i) => {
                                                return (
                                                    <li key={i}>
                                                        <Link href={'/service/' + result.id}>
                                                            <a>
                                                                {lang === 'ru'
                                                                    ? result.titleRU
                                                                    : lang === 'uz'
                                                                        ? result.titleUZ
                                                                        : result.titleEN}
                                                            </a>
                                                        </Link>
                                                    </li>
                                                );
                                            })
                                            : popular.slice(0, 5).map((result, i) => (
                                                <li key={i}>
                                                    <Link href={'/service/' + result.id}>
                                                        <a>
                                                            {lang === 'ru'
                                                                ? result.titleRU
                                                                : lang === 'uz'
                                                                    ? result.titleUZ
                                                                    : result.titleEN}
                                                        </a>
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                                </div>
                                <Input
                                    className="location-input"
                                    placeholder="Локация"
                                    value={Tashkent[lang]}
                                    readOnly
                                />
                                <button onClick={displayResultsMob}>
                                    <SearchIcon /> <span>{Search[lang]}</span>
                                </button>
                            </form>
                            <h1 className="headerTitle_"
                                dangerouslySetInnerHTML={{
                                    __html: Find_the_perfect_professional_for_you[lang],
                                }}
                            />
                            {recommendations ? (
                                <div
                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0 }}
                                    className="recommendations"
                                >
                                    {recommendations.map((recommendation, i) => {
                                        const title =
    lang === 'ru'
        ? recommendation.titleRU
        : lang === 'uz'
            ? recommendation.titleUZ
            : recommendation.titleEN;
                                        return (
                                            <div key={i} className="recommendation">
                                                <Link href={'/service/' + recommendation.id}>
                                                    <a>{title}</a>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="recommendations hidden">
                                    <div className="recommendation">
                                        <a href="#">placeholder</a>
                                    </div>
                                    <div className="recommendation">
                                        <a href="#">placeholder</a>
                                    </div>
                                    <div className="recommendation">
                                        <a href="#">placeholder</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>: isTabletOrMobile?
                    <div className="header-container row">
                        <div className="col" style={{width:'100%'}}>
                            <div className="header-form">

                                <form className="formS"style={{background: '#F9F9FA',padding:'14px',marginBottom:'50px'}} onSubmit={sendSearchQuery}>
                                    <div className="input-and-result">{
                                        isTabletOrMobile?
                                            <Link href ="/search">
                                                <Input
                                                    className="search-input"
                                                    placeholder={Search_service[lang]}
                                                    value={query}
                                                    handleChange={setQuery}
                                                    onClick={displayResults}
                                                />
                                            </Link>
                                            :<Input
                                                className="search-input"
                                                placeholder={Search_service[lang]}
                                                value={query}
                                                handleChange={setQuery}
                                                onClick={displayResults}
                                            />}
                                    {showResults && (
                                        <ul ref={searchResultsRef} className="search-results">
                                            {searchResults
                                                ? searchResults.slice(0, 5).map((result, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <Link href={'/service/' + result.id}>
                                                                <a>
                                                                    {lang === 'ru'
                                                                        ? result.titleRU
                                                                        : lang === 'uz'
                                                                            ? result.titleUZ
                                                                            : result.titleEN}
                                                                </a>
                                                            </Link>
                                                        </li>
                                                    );
                                                })
                                                : popular.slice(0, 5).map((result, i) => (
                                                    <li key={i}>
                                                        <Link href={'/service/' + result.id}>
                                                            <a>
                                                                {lang === 'ru'
                                                                    ? result.titleRU
                                                                    : lang === 'uz'
                                                                        ? result.titleUZ
                                                                        : result.titleEN}
                                                            </a>
                                                        </Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                                    </div>
                                    <Input
                                        className="location-input"
                                        placeholder="Локация"
                                        value={Tashkent[lang]}
                                        readOnly
                                    />
                                    <button >
                                        <SearchIcon /> <span>{Search[lang]}</span>
                                    </button>
                                </form>
                                <h1 className="headerTitle_"
                                    dangerouslySetInnerHTML={{
                                        __html: Find_the_perfect_professional_for_you[lang],
                                    }}
                                />
                                {recommendations ? (
                                    <div
                                        animate={{ opacity: 1 }}
                                        initial={{ opacity: 0 }}
                                        className="recommendations"
                                    >
                                        {recommendations.map((recommendation, i) => {
                                            const title =
                 lang === 'ru'
                     ? recommendation.titleRU
                     : lang === 'uz'
                         ? recommendation.titleUZ
                         : recommendation.titleEN;
                                            return (
                                                <div key={i} className="recommendation">
                                                    <Link href={'/service/' + recommendation.id}>
                                                        <a>{title}</a>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="recommendations hidden">
                                        <div className="recommendation">
                                            <a href="#">placeholder</a>
                                        </div>
                                        <div className="recommendation">
                                            <a href="#">placeholder</a>
                                        </div>
                                        <div className="recommendation">
                                            <a href="#">placeholder</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>:
                    <div className="header-container row">
                        <div className="col" style={{width:'100%'}}>
                            <div className="header-form">
                                <h1 className="headerTitle_"
                                    dangerouslySetInnerHTML={{
                                        __html: Find_the_perfect_professional_for_you[lang],
                                    }}
                                />
                                <form className="formS"onSubmit={sendSearchQuery}>
                                    <div className="input-and-result">{

                                        isTabletOrMobile?
                                            <Link href ="/search">
                                                <Input
                                                    className="search-input"
                                                    placeholder={Search_service[lang]}
                                                    value={query}
                                                    handleChange={setQuery}
                                                    onClick={displayResults}
                                                />
                                            </Link>
                                            :<Input
                                                className="search-input"
                                                placeholder={Search_service[lang]}
                                                value={query}
                                                handleChange={setQuery}
                                                onClick={displayResults}
                                            />}
                                    {showResults && (
                                        <ul ref={searchResultsRef} className="search-results">
                                            {searchResults
                                                ? searchResults.slice(0, 5).map((result, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <Link href={'/service/' + result.id}>
                                                                <a>
                                                                    {lang === 'ru'
                                                                        ? result.titleRU
                                                                        : lang === 'uz'
                                                                            ? result.titleUZ
                                                                            : result.titleEN}
                                                                </a>
                                                            </Link>
                                                        </li>
                                                    );
                                                })
                                                : popular.slice(0, 5).map((result, i) => (
                                                    <li key={i}>
                                                        <Link href={'/service/' + result.id}>
                                                            <a>
                                                                {lang === 'ru'
                                                                    ? result.titleRU
                                                                    : lang === 'uz'
                                                                        ? result.titleUZ
                                                                        : result.titleEN}
                                                            </a>
                                                        </Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                                    </div>
                                    <Input
                                        className="location-input"
                                        placeholder="Локация"
                                        value={Tashkent[lang]}
                                        readOnly
                                    />
                                    <button>
                                        <SearchIcon /> <span>{Search[lang]}</span>
                                    </button>
                                </form>
                                {recommendations ? (
                                    <div
                                        animate={{ opacity: 1 }}
                                        initial={{ opacity: 0 }}
                                        className="recommendations"
                                    >
                                        {recommendations.map((recommendation, i) => {
                                            const title =
                    lang === 'ru'
                        ? recommendation.titleRU
                        : lang === 'uz'
                            ? recommendation.titleUZ
                            : recommendation.titleEN;
                                            return (
                                                <div key={i} className="recommendation">
                                                    <Link href={'/service/' + recommendation.id}>
                                                        <a>{title}</a>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="recommendations hidden">
                                        <div className="recommendation">
                                            <a href="#">placeholder</a>
                                        </div>
                                        <div className="recommendation">
                                            <a href="#">placeholder</a>
                                        </div>
                                        <div className="recommendation">
                                            <a href="#">placeholder</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col" style={{width:'375px',height:'380px'}}>
                            <div className="header-image">
                                <SliderComponent lang={lang}/>

                                {/* <img style={{width:'350px',height:'409px', bottom:'unset'}}src="assets/images/wood.png" alt="pro" layout="fill" /> */}

                            </div>
                        </div>
                    </div>
            }
        </HeaderCss>
    );
};

export default memo(Header);
