import { motion } from 'framer-motion';
import React, { useState } from 'react';

import styled from 'styled-components';
import { handleLanguageChange } from '../../utils/handleChangeLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
    Leads,
    Logout,
    Messages,
    Profile,
    Switch_to_customer,
    Projects,
    Switch_to_Pro,
    Join_as_a_pro,
    // Sign_up,
    Log_in,
    Services,
    Settings,
    Coins,
    Nfunds,
    home
} from '../../helpers/dictionary';
import Link from 'next/link';

const DropDownCss = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 999;
  .head {
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    button {
      position: relative;
      border: 0;
      outline: none;
      font-size: 1.4rem;
      background-color: transparent;
      span {
        display: flex;
        align-items: center;
        svg {
          width: 2rem;
        }
      }
      .dropdown {
        position: absolute;
        right: 0;
        top: 120%;
        border-radius: 0.4rem;
        list-style: none;
        padding: 0;
        margin: 0;
        background: white;
        box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
        li {
          min-width: 60px;
          padding: 1rem 0;
          button {
            background: transparent;
            border: 0;
            font-size: 1.6rem;
            color: var(--color-jet);
            cursor: pointer;
            padding: 0;
            margin: 0;
            outline: none;
          }
        }
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 500;
    font-size: 2.2rem;
    padding: 1.6rem;
    hr {
      border-bottom: 1px solid #d8d8d8;
    }
    li,
    button {
      margin-bottom: 1.8rem;
      a {
        color: var(--color-jet);
        &.cta {
          color: var(--color-french-violet);
        }
        &.cta-blue {
          color: var(--color-flickr-blue);
        }
      }
    }
    button {
      border: 0;
      background-color: transparent;
      padding: 0;
      font-weight: bold;
      margin: 0;
    }
  }
`;

const Dropdown = ({
    lang,
    setShowMobileMenu,
    handleLogout,
    switchToPro,
    switchToClient,
}) => {
    const [dropdownTriggered, setDropdownTriggered] = useState(false);
    const dispatch = useDispatch();
    const router = useRouter();
    const handleLanguageSwitch = (e) => {
        handleLanguageChange(e, dispatch);
        const newLang = e.target.dataset.lang;

        router.push(router.asPath, router.asPath, { locale: newLang });
    };

    const auth = useSelector((state) => state.auth);
    const moveUser = (url) => {
        setShowMobileMenu(false);
        router.replace(url);
    };

    return (
        <DropDownCss>
            <div className="head">
                <button onClick={() => setShowMobileMenu(false)}>
                    <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                    </svg>
                </button>
                <button onClick={() => setDropdownTriggered((prevState) => !prevState)}>
                    <span>
                        {lang === 'eng' ? 'English' : lang === 'ru' ? 'Russian' : 'Uzbek'}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M16.939 7.939L12 12.879 7.061 7.939 4.939 10.061 12 17.121 19.061 10.061z"></path>
                        </svg>
                    </span>
                    <motion.ul
                        animate={{
                            y: dropdownTriggered ? 0 : 30,
                            opacity: dropdownTriggered ? 1 : 0,
                        }}
                        style={{
                            opacity: dropdownTriggered ? 1 : 0,
                            pointerEvents: dropdownTriggered ? 'auto' : 'none',
                        }}
                        transition={{ duration: 0.3 }}
                        className="dropdown"
                    >
                        <li>
                            <button
                                data-lang="ru"
                                onClick={(e) => handleLanguageSwitch(e, dispatch)}
                            >
                Py
                            </button>
                        </li>
                        <li>
                            <button
                                data-lang="uz"
                                onClick={(e) => handleLanguageSwitch(e, dispatch)}
                            >
                Uz
                            </button>
                        </li>
                        <li>
                            <button
                                data-lang="eng"
                                onClick={(e) => handleLanguageSwitch(e, dispatch)}
                            >
                Eng
                            </button>
                        </li>
                    </motion.ul>
                </button>
            </div>
            {auth.isAuthenticated ? (
                auth.user && auth.user.status === 'customer' ? (
                    <ul>
                        <li>
                            <button onClick={() => moveUser('/')}>
                                <a>{home[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => moveUser('/customer/activity')}>
                                <a>{Projects[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => moveUser('/customer/inbox')}>
                                <a>{Messages[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    switchToPro();
                                    setShowMobileMenu(false);
                                }}
                            >
                                <a className="cta">{Switch_to_Pro[lang]}</a>
                            </button>
                        </li>
                        <hr />
                        <li>
                            <button onClick={() => moveUser('/client/profile')}>
                                <a>{Profile[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    handleLogout();
                                    setShowMobileMenu(false);
                                }}
                            >
                                <a>{Logout[lang]}</a>
                            </button>
                        </li>
                    </ul>
                ) : (
                    <ul>
                        <li>
                            <button onClick={() => moveUser('/pro/leads')}>
                                <a>{Leads[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => moveUser('/pro/inbox')}>
                                <a>{Messages[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => moveUser('/profile/settings')}>
                                <a>{Settings[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => moveUser('/pro/services')}>
                                <a>{Services[lang]}</a>
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    switchToClient();
                                    setShowMobileMenu(false);
                                }}
                            >
                                <a className="cta-blue">{Switch_to_customer[lang]}</a>
                            </button>
                        </li>
                        <hr />
                        <li>
                            <button onClick={() => moveUser('/pro/profile')}>
                                <a>{Profile[lang]}</a>
                            </button>
                        </li>
                        <li className="coins">
                            <Link href="/pro/coins">
                                <a>

                                    <div className="typography">
                                        <h3 style={{fontSize:'15px' ,fontWeight:'500',color:'var(--color-french-violet)'}}>
                                            {Coins[lang]}:{' '}
                                            {auth.pro
                                                ? auth.pro.coin
                                                    ? auth.pro.coin.count
                                                    : 0
                                                : 0}
                                        </h3>
                                        <p style={{fontSize:'12px' ,fontWeight:'500',}}>
                                            {Nfunds[lang]}{' '}
                                            {auth.pro
                                                ? auth.pro.coin
                                                    ? auth.pro.coin.count * 2500
                                                    : 0
                                                : 0}
                                        </p>
                                    </div>
                                </a>
                            </Link>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    handleLogout();
                                    setShowMobileMenu(false);
                                }}
                            >
                                <a>{Logout[lang]}</a>
                            </button>
                        </li>
                    </ul>
                )
            ) : (
                <ul>
                    <li>
                        <button onClick={() => moveUser('/')}>
                            <a>{home[lang]}</a>
                        </button>
                    </li>
                    <li>
                        <button onClick={() => moveUser('/join-as-pro')}>
                            <a className="cta">{Join_as_a_pro[lang]}</a>
                        </button>
                    </li>
                    {/* <li>
                        <button onClick={() => moveUser('/register')}>
                            <a>{Sign_up[lang]}</a>
                        </button>
                    </li> */}
                    <li>
                        <button onClick={() => moveUser('/login')}>
                            <a>{Log_in[lang]}</a>
                        </button>
                    </li>
                </ul>
            )}
        </DropDownCss>
    );
};

export default Dropdown;
