import React from 'react';
import { useTranslation } from 'next-i18next';
import { Review as ReviewInterface } from '../../../../shared';
import ReviewsDetails from './ReviewsDetails';
import ReviewsSummary from './ReviewsSummary';
import { Divider, ReviewsContainer, ReviewTitle } from './style';
import { getReviewsData } from './utils';

interface ReviewProps {
    reviews: ReviewInterface[];
}

const Reviews = ({ reviews }: ReviewProps) => {
    const { t } = useTranslation();
    const { reviewsMap, average, reviewsCount } = getReviewsData(reviews);

    return (
        <ReviewsContainer>
            <ReviewTitle>
                {t('profile:Reviews')}
            </ReviewTitle>
            <ReviewsSummary
                average={average}
                reviewsCount={reviewsCount}
            />
            <Divider />
            <ReviewsDetails
                reviewsMap={reviewsMap}
                reviewsCount={reviewsCount}
            />
        </ReviewsContainer>
    );
};

export default Reviews;
