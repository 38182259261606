import { motion } from 'framer-motion';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import { logout,loadUser } from '../../actions/auth';
import { useWindowSize } from 'hooks/useWindowSize';

import {
    LEADS_LOADED,
    MESSAGES_LOADED,
    MESSAGES_LOADED_PRO,
    SWITCH_TO_CLIENT,
    TOGGLE_MODAL,
} from '../../actions/types';
import {
    Coins,
    Join_as_a_pro,
    Leads,
    Logout,
    Log_in,
    Menu,
    Messages,
    Nfunds,
    Profile,
    Projects,
    Services,
    Settings,
    // Sign_up,
    Switch_to_customer,
    Switch_to_Pro,
} from '../../helpers/dictionary';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import ChevronDown from '../../svgs/chevron-down.svg';
import Logo from '../../svgs/logo.svg';
import UserIcon from '../../svgs/user.svg';
import { handleLanguageChange } from '../../utils/handleChangeLanguage';
import Link from '../Link';
import Flex from '../styles/Flex';
import Dropdown from './Dropdown';
import Head from 'next/head';
import axios from '../../utils/axios';
import io from 'socket.io-client';

let socket;

Router.onRouteChangeStart = () => {
    NProgress.start();
};
Router.onRouteChangeComplete = () => {
    NProgress.done();
};
Router.onRouteChangeError = () => {
    NProgress.done();
};

const NavbarCss = styled.div`
  position: ${(props) => (props.stickyNav ? 'sticky' : 'relative')};
  background: white;

  width: 100%;
  z-index: 10;
  padding: 0 1.6rem;
  top: 0;
  h4 {
    margin: 0;
    margin-right: 1.2rem;
    font-size: 1.5rem;
    color: #858a8f;
  }
  span.error {
    display: inline-block;
    background: #fff0f1;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    padding: 1.2rem 0;
    font-weight: 600;
    font-size: 15px;
    color: var(--color-red);
    margin: 2.4rem 0;
  }
  .logged-user-menu {
    margin: 0;
    display: flex;
    align-items: center;
    list-style: none;
    li {
      height: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.9rem 1.6rem;
        cursor: pointer;
        svg {
          fill: #565459;
        }
        &.profile-item {
          background: #f4f6fb;
          border-radius: 4px;
          padding: 1rem 1.6rem;
        }
        &.active-projects {
          background: var(--color-indigo);
          svg {
            fill: white;
          }
          span{
              color: white;
              opacity: 1;
          }
        }
  
        &.active-messages {
          background: var(--color-flickr-blue);
          svg {
            fill: white;
          }
          span{
              color: white;
              opacity: 1;
          }
        }
        &.active-profile {
          background: var(--color-flickr-blue);
          span {
            color: white;
          }
        }
        span {
          font-weight: 600;
          font-size: 15px;
          color: var(--color-jet);
        }
      }
    }
  }
  .button-trigger {
    padding: 1.6rem 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: none;
    h6 {
      margin: 0;
      font-size: 1rem;
    }
    &.mr {
      margin-right: 2rem;
    }
    svg {
      width: 2.4rem;
      fill: var(--color-jet);
    }
    .hamburger-icon {
      width: 2.2rem;
      height: 0.2rem;
      background: var(--color-jet);
      border-radius: 5px;
      transition: all 0.5s ease-in-out;
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 2.2rem;
        height: 0.2rem;
        background: var(--color-jet);
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &:before {
        transform: translateY(-0.6rem);
      }
      &:after {
        transform: translateY(0.6rem);
      }

      &.open {
        transform: translateX(-5rem);
        background: transparent;
        box-shadow: none;
        &:before {
          transform: rotate(45deg) translate(35px, -35px);
        }
        &:after {
          transform: rotate(-45deg) translate(35px, 35px);
        }
      }
    }
  }
  .logo-section {
    .logo-svg svg {
      width: 6.4rem;
    }
    .language-dropdown {
      display: none;
    }
  }
  .menu-links {
    display: none;
  }
  .overlay-background {
    width: 100%;
    height: 90vh;
    position: absolute;
    left: 0;
    z-index: 2;
    top: 100%;
    background: rgba(0, 0, 0, 0.75);
    border-top: 2px solid #e2e2e9;
    .mobile-menu-items,
    .login-form {
      width: 100%;
      background: white;
      border-radius: 0px 0px 4px 4px;
      padding: 2rem 0;
      overflow: hidden;
    }
    .signup-form {
      width: 100%;
      background: white;
      box-shadow: 0px 63px 50px rgba(67, 67, 68, 0.07);
      border-radius: 0px 0px 4px 4px;
      padding: 1.2rem 0;
      overflow: scroll;
      input {
        padding: 1.2rem;
      }
      .two-fields {
        display: flex;
        justify-content: space-between;
        .input {
          width: 48%;
        }
      }
      .switch {
        position: relative;
        .switchers {
          z-index: 2;
          position: absolute;
          top: -1rem;
          right: 0;
          display: flex;
          align-items: center;
          padding: 1rem 0;
          span.button {
            margin: 0;
            padding: 0;
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            color: #565459;
            &.active {
              font-weight: 600;
              background: transparent;
              color: ${(props) => props.theme.colors.flickrBlue};
            }
          }
          .switch-input {
            width: 3.6rem;
            height: 0.4rem;
            color: white;
            position: relative;
            margin: 0 0.8rem;
            .line {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              background: #e2e2e9;
              border-radius: 2px;
            }
            .circle {
              position: absolute;
              top: -0.7rem;
              left: 0;
              transition: left 0.5s ease-in-out;
              z-index: 1;
              width: 1.6rem;
              height: 1.6rem;
              background: #ffffff;
              box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1),
                0px 1px 3px rgba(0, 0, 0, 0.2);
              border-radius: 3rem;
              display: flex;
              justify-content: center;
              align-items: center;
              &.right {
                left: 80%;
              }
              span {
                width: 0.8rem;
                height: 0.8rem;
                background: var(--color-flickr-blue);
                border-radius: 3rem;
                display: inline-block;
              }
            }
          }
        }
      }
      .agreements {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        text-align: center;
        color: #565459;
        margin-top: -1.2rem;
        .row {
          display: flex;
          span {
            margin: 0 0.5rem;
          }
          a {
            color: var(--color-flickr-blue);
          }
        }
      }
      .register-button {
        background: var(--color-flickr-blue) !important;
        width: 100%;
        margin-top: 2.6rem !important;
        display: inline-block;
        color: white;
        border-radius: 4px;
        outline: none;
        padding: 1.2rem 0;
        border: 0;
        font-weight: 600;
        font-size: 15px;
      }
      h4 {
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        color: #858a8f;
        margin-right: 1.2rem;
      }
      .forgot-password {
        color: var(--color-flickr-blue);
        font-size: 1.5rem;
        padding: 0 !important;
        margin: 0;
      }
    }
    .login-form {
      .auth-buttons {
        border: 2px solid ${(props) => props.theme.colors.flickrBlueShade};
      }
      button {
        padding: 1.2rem 1.6rem;
        margin: 0;
        color: B5B5BE;
        background: transparent;
        border: 0;
        font-weight: 600;
        font-size: 1.5rem;
        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
        &:last-child {
          border-radius: 0px 4px 4px 0px;
        }
        &.active {
          background-color: ${(props) => props.theme.colors.flickrBlueShade};
          color: var(--color-flickr-blue);
        }
      }
    }
    .mobile-menu-items {
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 2rem 0 0.4rem;
        li {
          margin: 0.5rem 0;
          font-size: 1.5rem;
          a {
            color: #565459;
            font-weight: 600;
          }
        }
      }
    }
  }
  .remember-me {
    font-size: 1.5rem;
    font-weight: 600;
    input {
      margin-right: 0.6rem;
    }
  }
  .forgot-password {
    color: var(--color-flickr-blue);
    font-size: 1.5rem;
    padding: 0 !important;
    margin: 0;
  }
  .login-button {
    background: var(--color-flickr-blue) !important;
    width: 100%;
    margin-top: 2.6rem !important;
    display: inline-block;
    color: white;
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    padding: 2.2rem 1.6rem;
    
    .mobile-menu-trigger,
    .logged-user-menu,
    .user-login-trigger {
      display: none;
    }
    .logo-section {
      .logo-svg svg {
        width: 8.2rem;
      }
      .language-dropdown {
        display: block;
        margin-left: 2rem;
        position: relative;
        button {
          background: transparent;
          border: 0;
          font-size: 1.6rem;
          color: ${(props) => props.theme.colors.jet};
          cursor: pointer;
          padding: 0;
          outline: none;
          margin: 0;
        }
        svg {
          fill: #c7c7cc;
          width: 2.4rem;
        }
        .dropdown {
          z-index: 10;
          padding: 0;
          margin: 0;
          position: absolute;
          left: 0;
          top: 100%;
          list-style: none;
          background: white;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          button {
            padding: 1.4rem;
            margin: 0;
            width: 100%;
            &:hover {
              background: #e8e8e8;
            }
          }
        }
      }
    }
    .menu-links {
      display: block;
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 1.5rem;
        border-radius: 4px;
        margin-left: 4rem;
        a {
          color: ${(props) => props.theme.colors.jet};
          span.badge {
            min-width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: var(--color-flickr-blue);
            display: inline-block;
            margin-left: 0.2rem;
            color: white;
            white-space: nowrap;
            text-align: center;
            font-size: 12px;
            line-height: 20px;
          }
        }
        &.cta {
          background-color: ${(props) => props.theme.colors.frenchViolet};
          padding: 1.2rem 2.8rem;
          a {
            color: white;
          }
        }
      }
      .coins a {
        background: #e6e6e6;
        border-radius: 0 0 4px 4px;
        padding: 1.5rem;
        display: flex;
        align-items: center;
        svg {
          margin-right: 0.8rem;
        }
        h3 {
          margin: 0;
          font-size: 1.6rem;
        }
        p {
          margin: 0;
        }
      }
      .logged-in {
        position: relative;
        button {
          display: flex;
          align-items: center;
          background-color: ${(props) => props.theme.colors.frenchViolet};
          padding: 1.2rem 2.8rem;
          color: white;
          margin: 0;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
          font-weight: 600;
          font-size: 15px;
          svg {
            margin-left: 0.4rem;
            width: 2rem;
            fill: white;
          }
        }
        ul {
          position: absolute;
          top: 120%;
          right: 0;
          min-width: 20rem;
          background: white;
          box-shadow: 0 4px 15px rgb(51 51 51 / 0.1);
          padding: 0;
          margin: 0;
          list-style: none;
          overflow: hidden;
          li {
            margin: 0;
            a {
              display: inline-block;
              min-width: 100%;
              padding: 0.9em;
              cursor: pointer;
              &.cta {
                color: ${(props) => props.theme.colors.frenchViolet};
                font-weight: 600;
              }
              &.switch-pro {
                color: var(--color-flickr-blue);
              }
              &:hover {
                color: var(--color-french-violet);
                background-color: #f4f6fb;
              }
            }
          }
        }
      }
      .client button {
        background: var(--color-flickr-blue);
      }
    }
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }
`;

const Navbar = ({ lang }) => {
    const router = useRouter();
    const { locale } = router;
    const dispatch = useDispatch();

    const handleLanguageSwitch = (e) => {
        handleLanguageChange(e, dispatch);
        const newLang = e.target.dataset.lang;

        router.push(router.asPath, router.asPath, { locale: newLang });
    };

    const [dropdownTriggered, setDropdownTriggered] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showAuthenticatedDropdown, setShowAuthenticatedDropdown] = useState(
        false
    );
    const screenWidth= useWindowSize().width;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const[notification,setNotification] = useState(null);
    const auth = useSelector((state) => state.auth);
    useEffect(()=>{

        const fetchDataUser = async () => {
            const messagesData = await axios.get('/api/v1/message/user');

            dispatch({
                type: MESSAGES_LOADED,
                payload: messagesData.data,
            });
        };

        if(auth?.user?.status=='customer'){
            fetchDataUser();
        }



        if(typeof window !== 'undefined'){
            socket = io(process.env.BACKEND_URL, { transports: ['websocket'] });
            socket.on('getmessage',async(ds)=>{
                if(ds.user&&ds.user!=''&&ds.user==JSON.parse(localStorage.getItem('userId'))){
                    const fetchDataUser = async () => {
                        const messagesData = await axios.get('/api/v1/message/user');

                        dispatch({
                            type: MESSAGES_LOADED,
                            payload: messagesData.data,
                        });
                    };


                    fetchDataUser();

                }

                if(ds.pro&&ds.pro!=null&&ds.pro==JSON.parse(localStorage.getItem('proid'))){
                    const fetchData = async () => {
                        const messagesData = await axios.get(
                            '/api/v1/message/pro/' + JSON.parse(localStorage.getItem('proid'))
                        );

                        setNotification();

                        dispatch({
                            type: MESSAGES_LOADED_PRO,
                            payload: messagesData.data,
                        });
                    };

                    fetchData();
                }

            });
            socket.on('getLead', async (d)=>{

                if(d==JSON.parse(localStorage.getItem('proid'))){

                    const { data } = await axios.get(`/api/v1/order/pro-leads?id=${localStorage.getItem('proid')}`, {
                        withCredentials: true,
                    });

                    dispatch({
                        type: LEADS_LOADED,
                        payload: data,
                    });
                }

            });
        }

        const fetchD= async () => {
            const messagesData = await axios.get(
                '/api/v1/message/pro/' + JSON.parse(localStorage.getItem('proid'))
            );

            dispatch({
                type: MESSAGES_LOADED_PRO,
                payload: messagesData.data,
            });
        };
        if(auth?.user?.status=='pro'){
            fetchD();
        }
        return () => {
            if(socket){
                socket.disconnect();
                socket.off();
            }
        };




    },[]);


    const leads = useSelector((state) => state.leads);
    const stickyNav = useSelector((state) => state.settings.stickyNav);

    const messages = useSelector((state) => state.messages);

    const authenticatedDropdownRef = useRef(null);
    useOutsideAlerter(authenticatedDropdownRef, setShowAuthenticatedDropdown);

    const handleUserLoginTrigger = () => {
        // if (modals.signupForm) {
        //     setShowMobileMenu(false);
        //     dispatch({
        //         type: TOGGLE_MODAL,
        //         payload: {
        //             signupForm: !modals.signupForm,
        //         },
        //     });
        // } else {
        //     setShowMobileMenu(false);
        //     dispatch({
        //         type: TOGGLE_MODAL,
        //         payload: {
        //             loginForm: !modals.loginForm,
        //         },
        //     });
        // }
        router.push('/login');
    };

    const switchToClient = async () => {
        await axios.post('/api/v1/auth/switch-status', {
            status: 'customer',
        });

        dispatch({
            type: SWITCH_TO_CLIENT,
        });
        await dispatch(loadUser());
        router.replace('/customer/activity');
    };

    const switchToPro = async () => {
        const { data } = await axios.get('/api/v1/pro');

        if (data.pro) {
            await axios.post('/api/v1/auth/switch-status', {
                status: 'pro',
            });

            await dispatch(loadUser());

            await router.replace('/pro/leads');
        } else {
            await router.replace('/join-as-pro?switch=true');
        }
    };

    useEffect(() => {
        const fetchLeads = async () => {
            const { data } = await axios.get(`/api/v1/order/pro-leads?id=${localStorage.getItem('proid')}`, {
                withCredentials: true,
            });

            dispatch({
                type: LEADS_LOADED,
                payload: data,
            });
        };

        if (auth.pro) {
            fetchLeads();
        }
    }, [auth.pro]);

    return (
        <NavbarCss id="topnav" stickyNav={stickyNav}>
            <Head>
                <link rel="stylesheet" href="/nprogress.css" />
            </Head>

            <Flex justify="space-between" align="center">
                {auth.isAuthenticated && auth.user ? (
                    <Flex align="center">
                        <div className="logo-section">
                            <Flex align="center">
                                <button
                                    onClick={() => {
                                        dispatch({
                                            type: TOGGLE_MODAL,
                                            payload: {
                                                loginForm: false,
                                                signupForm: false,
                                            },
                                        });
                                        setShowMobileMenu((prevState) => !prevState);
                                    }}
                                    className="button-trigger mobile-menu-trigger mr-1"
                                >
                                    {showMobileMenu ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M4 6H20V8H4zM4 11H20V13H4zM4 16H20V18H4z"></path>
                                        </svg>
                                    )}
                                    <h6>{Menu[lang]}</h6>
                                </button>
                                <Link href={auth?.user?.status === 'pro' ? '/pro/leads' : '/'}>
                                    <a className="logo-svg">
                                        <Logo />
                                    </a>
                                </Link>
                                {/* <button
                  onClick={() => {
                    dispatch({
                      type: TOGGLE_MODAL,
                      payload: {
                        loginForm: false,
                        signupForm: false,
                      },
                    });
                    setShowMobileMenu((prevState) => !prevState);
                  }}
                  className="button-trigger mobile-menu-trigger mr"
                >
                  <div
                    className={
                      showMobileMenu ? 'hamburger-icon open' : 'hamburger-icon'
                    }
                  ></div>
                </button> */}
                                <div
                                    onClick={() =>
                                        setDropdownTriggered((prevState) => !prevState)
                                    }
                                    onMouseEnter={()=>setDropdownTriggered(true)}
                                    onMouseLeave={()=>setDropdownTriggered(false)}
                                    className="language-dropdown"
                                >
                                    <Flex direction="column">
                                        <button>
                                            <Flex align="center">
                                                {locale ? (
                                                    <span style={{ textTransform: 'capitalize' }}>
                                                        {locale}
                                                    </span>
                                                ) : (
                                                    <span>Py</span>
                                                )}
                                                <ChevronDown />
                                            </Flex>
                                        </button>
                                        <motion.ul
                                            animate={{
                                                y: dropdownTriggered ? 0 : 30,
                                                opacity: dropdownTriggered ? 1 : 0,
                                            }}
                                            style={{
                                                opacity: dropdownTriggered ? 1 : 0,
                                                pointerEvents: dropdownTriggered ? 'auto' : 'none',
                                            }}
                                            transition={{ duration: 0.3 }}
                                            className="dropdown"
                                        >
                                            <li>
                                                <button
                                                    data-lang="ru"
                                                    onClick={handleLanguageSwitch}
                                                >
                          Py
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    data-lang="uz"
                                                    onClick={handleLanguageSwitch}
                                                >
                          Uz
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    data-lang="eng"
                                                    onClick={handleLanguageSwitch}
                                                >
                          Eng
                                                </button>
                                            </li>
                                        </motion.ul>
                                    </Flex>
                                </div>
                            </Flex>
                        </div>
                    </Flex>
                ) : (
                    <>
                        <button
                            onClick={() => {
                                dispatch({
                                    type: TOGGLE_MODAL,
                                    payload: {
                                        loginForm: false,
                                        signupForm: false,
                                    },
                                });
                                setShowMobileMenu((prevState) => !prevState);
                            }}
                            className="button-trigger mobile-menu-trigger"
                        >
                            {showMobileMenu ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"></path>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M4 6H20V8H4zM4 11H20V13H4zM4 16H20V18H4z"></path>
                                </svg>
                            )}
                            <h6>MENU</h6>
                        </button>

                        <div className="logo-section">
                            <Flex align="center">
                                <a  aria-label ="topx" href="/">
                                    <span className="logo-svg ">
                                        <Logo />
                                    </span>
                                </a>
                                <div
                                    onClick={() =>
                                        setDropdownTriggered((prevState) => !prevState)
                                    }

                                    onMouseEnter={()=>setDropdownTriggered(true)}
                                    onMouseLeave={()=>setDropdownTriggered(false)}

                                    className="language-dropdown"
                                >
                                    <Flex direction="column">
                                        <button>
                                            <Flex align="center">
                                                {lang ? (
                                                    <span style={{ textTransform: 'capitalize' }}>
                                                        {lang}
                                                    </span>
                                                ) : (
                                                    <span>Py</span>
                                                )}
                                                <ChevronDown />
                                            </Flex>
                                        </button>
                                        <motion.ul
                                            animate={{
                                                y: dropdownTriggered ? 0 : 30,
                                                opacity: dropdownTriggered ? 1 : 0,
                                            }}
                                            style={{
                                                opacity: dropdownTriggered ? 1 : 0,
                                                pointerEvents: dropdownTriggered ? 'auto' : 'none',
                                            }}
                                            transition={{ duration: 0.3 }}
                                            className="dropdown"
                                        >
                                            <li>
                                                <button
                                                    data-lang="ru"
                                                    onClick={(e) => handleLanguageSwitch(e)}
                                                >
                          Py
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    data-lang="uz"
                                                    onClick={(e) => handleLanguageSwitch(e)}
                                                >
                          Uz
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    data-lang="eng"
                                                    onClick={(e) => handleLanguageSwitch(e)}
                                                >
                          Eng
                                                </button>
                                            </li>
                                        </motion.ul>
                                    </Flex>
                                </div>
                            </Flex>
                        </div>
                    </>
                )}
                <ul className="menu-links">
                    <Flex align="center">
                        {auth.isAuthenticated ? (
                            auth?.user?.status === 'pro' ? (
                                <>
                                    <li>
                                        <Link href="/pro/leads">
                                            <a>
                                                {Leads[lang]}
                                                {leads.unreadLeads > 0 && (
                                                    <span className="badge">{leads&& leads.unreadLeads}</span>
                                                )}
                                            </a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            href={
                                                auth?.user?.status === 'pro'
                                                    ? '/pro/inbox'
                                                    : '/customer/inbox'
                                            }
                                        >
                                            <a>{Messages[lang]}
                                                {messages.unreadMessages> 0 && (
                                                    <span className="badge">{messages&& messages.unreadMessages}</span>
                                                )}</a>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link href="/pro/services">
                                            <a>{Services[lang]}</a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link href="/pro/profile">
                                            <a>{Profile[lang]}</a>
                                        </Link>
                                    </li>
                                    <li ref={authenticatedDropdownRef} className="logged-in">
                                        <button
                                            type="button"
                                            style={{fontSize:'15px' ,fontWeight:'600'}}
                                            onClick={() =>
                                                setShowAuthenticatedDropdown((prevState) => !prevState)
                                            }
                                            onMouseEnter={()=> setShowAuthenticatedDropdown(true)}
                                        >
                                            {auth.user.firstName} <ChevronDown />
                                        </button>
                                        <ul
                                            style={{
                                                height: showAuthenticatedDropdown ? 'auto' : 0,
                                            }}
                                            onMouseLeave={()=>setShowAuthenticatedDropdown(false)}
                                        >
                                            <li>
                                                <Link style={{color:'black'}} href="/profile/settings">
                                                    <a style={{fontSize:'15px' ,fontWeight:'500',color:'black',paddingBottom:'-18px'}}  className="cta">
                                                        {Settings[lang]}
                                                    </a>
                                                </Link>
                                            </li>
                                            <li>
                                                <a style={{fontSize:'15px' ,fontWeight:'500',color:'black',paddingBottom:'-18px'}} onClick={switchToClient} className="cta">
                                                    {Switch_to_customer[lang]}
                                                </a>
                                            </li>

                                            <li>
                                                <a style={{fontSize:'15px' ,fontWeight:'500',color:'black',paddingBottom:'-18px'}} onClick={() => dispatch(logout())}>{Logout[lang]}</a>
                                            </li>
                                            <div className="coins">
                                                <Link href="/pro/coins">
                                                    <a>

                                                        <div className="typography">
                                                            <h3 style={{fontSize:'15px' ,fontWeight:'500',color:'var(--color-french-violet)'}}>
                                                                {Coins[lang]}:{' '}
                                                                {auth.pro
                                                                    ? auth.pro.coin
                                                                        ? auth.pro.coin.count
                                                                        : 0
                                                                    : 0}
                                                            </h3>
                                                            <p style={{fontSize:'12px' ,fontWeight:'500',}}>
                                                                {Nfunds[lang]}{' '}
                                                                {auth.pro
                                                                    ? auth.pro.coin
                                                                        ? auth.pro.coin.count * 2500
                                                                        : 0
                                                                    : 0}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </Link>
                                            </div>
                                        </ul>
                                    </li>
                                </>
                            ) : (
                                <>

                                    <li>
                                        <Link href="/customer/activity">
                                            <a>{Projects[lang]}</a>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            href={
                                                auth?.user?.status === 'pro'
                                                    ? '/pro/inbox'
                                                    : '/customer/inbox'
                                            }
                                        >
                                            <a>{Messages[lang]}
                                                {messages.unreadMessages> 0 && (
                                                    <span className="badge">{messages&& messages.unreadMessages}</span>)}</a>
                                        </Link>
                                    </li>
                                    <li
                                        ref={authenticatedDropdownRef}
                                        className="logged-in client"
                                    >
                                        <button
                                            onMouseEnter={()=> setShowAuthenticatedDropdown(true)}
                                            onClick={() =>
                                                setShowAuthenticatedDropdown((prevState) => !prevState)
                                            }
                                        >
                                            {auth.user.firstName} <ChevronDown />
                                        </button>
                                        <ul
                                            style={{
                                                height: showAuthenticatedDropdown ? 'auto' : 0,
                                            }}
                                            onMouseLeave={()=>setShowAuthenticatedDropdown(false)}
                                        >
                                            <li>
                                                <Link href="/client/profile">
                                                    <a>{Profile[lang]}</a>
                                                </Link>
                                            </li>
                                            <li>
                                                <a onClick={switchToPro} className="cta switch-pro">
                                                    {auth.user.isPro ? Switch_to_Pro[lang] : Join_as_a_pro[lang]}
                                                </a>
                                            </li>
                                            <li>
                                                <a onClick={() => dispatch(logout())}>{Logout[lang]}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            )
                        ) : (
                            <>
                                <li>
                                    <Link href="/login">
                                        <a>{Log_in[lang]}</a>
                                    </Link>
                                </li>

                                <li className="cta">
                                    <Link href="/join-as-pro">
                                        <a>{Join_as_a_pro[lang]}</a>
                                    </Link>
                                </li>
                            </>
                        )}
                    </Flex>
                </ul>
                {auth.isAuthenticated && auth.user ? (
                    <ul className="logged-user-menu">
                        <li>
                            <Link

                                activeClassName={auth?.user?.status === 'pro'?'active-projects':'active-messages'}
                                href={
                                    auth?.user?.status === 'pro'
                                        ? '/pro/leads'
                                        : '/customer/activity'
                                }
                            >
                                <a    style={{height:'69px',display:'table-caption',textAlign:'center'}}>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.66667 5C1.66667 3.15905 3.15905 1.66667 5 1.66667H15C16.8409 1.66667 18.3333 3.15905 18.3333 5V15C18.3333 16.8409 16.8409 18.3333 15 18.3333H5C3.15905 18.3333 1.66667 16.8409 1.66667 15V5ZM5 3.33333H15C15.9205 3.33333 16.6667 4.07953 16.6667 5V9.16667H3.33333V5C3.33333 4.07953 4.07953 3.33333 5 3.33333ZM3.33333 10.8333V15C3.33333 15.9205 4.07953 16.6667 5 16.6667H15C15.9205 16.6667 16.6667 15.9205 16.6667 15V10.8333H3.33333Z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.70833 5.83333C7.70833 5.48816 7.98816 5.20833 8.33333 5.20833H11.6667C12.0118 5.20833 12.2917 5.48816 12.2917 5.83333C12.2917 6.17851 12.0118 6.45833 11.6667 6.45833H8.33333C7.98816 6.45833 7.70833 6.17851 7.70833 5.83333ZM7.70833 13.3333C7.70833 12.9882 7.98816 12.7083 8.33333 12.7083H11.6667C12.0118 12.7083 12.2917 12.9882 12.2917 13.3333C12.2917 13.6785 12.0118 13.9583 11.6667 13.9583H8.33333C7.98816 13.9583 7.70833 13.6785 7.70833 13.3333Z"
                                        />
                                    </svg>
                                    <span style={{display:screenWidth<380&&'none',fontSize:'12px'}}className="text-l">{auth?.user?.status === 'pro'?Leads[lang]:Projects[lang]}</span>
                                </a>

                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClassName={auth?.user?.status === 'pro'?'active-projects':'active-messages'}
                                href={
                                    auth?.user?.status === 'pro' ? '/pro/inbox' : '/customer/inbox'
                                }
                            >
                                <a  style={{height:'69px',display:'table-caption',textAlign:'center'}}>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.73238 0.73648C5.34285 0.491437 7.55135 0.25 10 0.25C12.4487 0.25 14.6571 0.491437 16.2676 0.73648C17.8581 0.978481 19.1074 2.14003 19.3782 3.712C19.5785 4.87514 19.75 6.35223 19.75 8C19.75 9.64777 19.5785 11.1249 19.3782 12.288C19.1074 13.86 17.8581 15.0215 16.2676 15.2635C14.7944 15.4877 12.8205 15.7089 10.62 15.7449L10.5538 15.746L4.38154 19.3932C3.88158 19.6887 3.25 19.3283 3.25 18.7476V15.1636L3.07889 15.1067C1.80806 14.6841 0.854433 13.6384 0.62183 12.288C0.42148 11.1249 0.25 9.64777 0.25 8C0.25 6.35223 0.42148 4.87514 0.62183 3.71201C0.892602 2.14003 2.1419 0.978481 3.73238 0.73648ZM10 1.75C7.64591 1.75 5.51533 1.98246 3.95802 2.21941C2.96373 2.3707 2.25414 3.07211 2.10006 3.96663C1.91109 5.0637 1.75 6.4541 1.75 8C1.75 9.5459 1.91109 10.9363 2.10006 12.0334C2.23356 12.8084 2.7764 13.4254 3.5522 13.6834L4.75 14.0817V17.4332L10.1324 14.2527L10.5954 14.2451C12.7131 14.2104 14.618 13.9973 16.042 13.7806C17.0363 13.6293 17.7459 12.9279 17.8999 12.0334C18.0889 10.9363 18.25 9.5459 18.25 8C18.25 6.4541 18.0889 5.0637 17.8999 3.96663C17.7459 3.07211 17.0363 2.3707 16.042 2.21941C14.4847 1.98246 12.3541 1.75 10 1.75Z"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.25 6C4.25 5.58579 4.58579 5.25 5 5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H5C4.58579 6.75 4.25 6.41421 4.25 6ZM4.25 10C4.25 9.58579 4.58579 9.25 5 9.25H9C9.41421 9.25 9.75 9.58579 9.75 10C9.75 10.4142 9.41421 10.75 9 10.75H5C4.58579 10.75 4.25 10.4142 4.25 10Z"
                                        />
                                    </svg>
                                    <span style={{display:screenWidth<380&&'none',fontSize:'12px'}}>{Messages[lang]}</span>
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link
                                activeClassName={auth?.user?.status === 'pro'?'active-projects':'active-messages'}
                                href={
                                    auth?.user?.status === 'pro'
                                        ? '/pro/profile'
                                        : '/client/profile'
                                }
                            >
                                <a className="profile-item">
                                    <span>{auth.user.firstName[0]}</span>
                                </a>
                            </Link>
                        </li>
                    </ul>
                ) : (
                    <button
                        onClick={() => handleUserLoginTrigger()}
                        className="button-trigger user-login-trigger"
                        name="dropdown-menu"
                    >
                        <UserIcon />
                    </button>
                )}
            </Flex>
            {showMobileMenu && (
                <Dropdown
                    lang={lang}
                    setShowMobileMenu={setShowMobileMenu}
                    handleLogout={() => dispatch(logout())}
                    switchToPro={switchToPro}
                    switchToClient={switchToClient}
                />
            )}
        </NavbarCss>
    );
};

export default Navbar;