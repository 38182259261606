import styled from 'styled-components';
import UploadIconComponent from './UploadIcon';
import Button from 'antd/lib/button';

export const DropZoneContainer = styled.div`
    cursor: pointer;
    border: 1px dashed #D7DFE9;
    background-color: #ffffff;
    border-radius: 2px;
    color: var(--color-grey-100);
    &:focus {
      border-color: var(--color-flickr-blue);
    }
`;

export const DropZoneHintContainer = styled.div`
    display: grid;
    grid-template-columns: 29px auto;
    grid-template-rows: 18px;
    justify-content: center;
    align-items: center;
    gap: 0 14px;
    grid-template-areas: 'icon text'
                         'icon text';
    padding: 20px 0;
`;

export const UploadIcon = styled(UploadIconComponent)`
    grid-area: icon;
`;

export const FileUploadContainer = styled.div`
    display: grid;
    gap: 30px 0;
`;

export const SubmitButton = styled(Button)`
    justify-self: center;
    height: 42px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
`;

export const Body15 = styled.span`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
`;

export const UploadPreviewContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    grid-template-columns: 40px 1fr auto;
    align-items: center;
`;

export const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
        height: 16px;
    }
`;

export const ProfileVerificationHintContainer = styled.div`
    display: grid;
    grid-template-columns: auto 40px;
    gap: 0 20px
`;