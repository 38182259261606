import React, { useState, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import LazyLoad from 'react-lazyload';
import Container from '../styles/Container';
import Flex from '../styles/Flex';
import {
    All_services,
    More,
    Pros_near_you,
    See_all_in_category,
} from '../../helpers/dictionary';
import { CategoriesCss } from '../styles/homepage';
import { renderIcon } from '../../utils/renderIcon';

const Categories = ({ main, lang }) => {
    const [activeCategory, setActiveCategory] = useState(0);

    const handleCategoryTabsChange = (e) => {
        if (e.target.dataset.index) {
            setActiveCategory(e.target.dataset.index);
        }
    };

    const categoryImage =
    main[activeCategory] && main[activeCategory].imageUrl
        ? process.env.ASSETS_URL + '/' + main[activeCategory].imageUrl
        : '';

    const description = main[activeCategory]
        ? lang === 'ru'
            ? main[activeCategory].descriptionRU
            : lang === 'uz'
                ? main[activeCategory].descriptionUZ
                : main[activeCategory].descriptionEN
        : '';

    const services =
    main[activeCategory] && main[activeCategory].services
        ? main[activeCategory].services
        : [];

    const mainServices = services
        .filter((s) => s.main)
        .reverse()
        .slice(0, 3);

    const current = main[activeCategory];
    const currentIcon = current ? current.iconType.split('/')[0] : '';
    const currentIconStyle = current ? current.iconType.split('/')[1] : '';

    return (
        <LazyLoad className="lazyS"style={{marginTop:'70px'}}once>
            <CategoriesCss categoryImage={categoryImage}>
                <Container className="categ">
                    <div className="categories-tabs">
                        <Flex justify="space-between" align="center">
                            <Flex>
                                {main
                                    .filter((item) => item.showMain)
                                    .map((category, index) => {
                                        return (
                                            <button
                                                key={
                                                    lang === 'ru'
                                                        ? category.titleRU
                                                        : lang === 'uz'
                                                            ? category.titleUZ
                                                            : category.titleEN
                                                }
                                                onClick={handleCategoryTabsChange}
                                                data-index={index}
                                                className={
                                                    parseInt(activeCategory) === index ? 'active-tab' : ''
                                                }
                                            >
                                                {lang === 'ru'
                                                    ? category.titleRU
                                                    : lang === 'uz'
                                                        ? category.titleUZ
                                                        : category.titleEN}
                                            </button>
                                        );
                                    })}
                            </Flex>
                            <Link href="/categories">
                                <a className="more">
                                    {More[lang]}
                                    <svg
                                        width="7"
                                        height="12"
                                        viewBox="0 0 7 12"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.528575 0.861766C0.268226 1.12212 0.268226 1.54423 0.528575 1.80458L4.72384 5.99984L0.528576 10.1951C0.268226 10.4554 0.268226 10.8776 0.528576 11.1379C0.788925 11.3983 1.21104 11.3983 1.47139 11.1379L6.13805 6.47124C6.3984 6.21089 6.3984 5.78878 6.13805 5.52843L1.47138 0.861766C1.21103 0.601417 0.788925 0.601417 0.528575 0.861766Z"
                                        />
                                    </svg>
                                </a>
                            </Link>
                        </Flex>
                    </div>
                    <div className="category-data">
                        <Link href="/categories">
                            <a className="header">
                                <div className="top">
                                    {/* <Icon type={currentIcon} theme={currentIconStyle} /> */}
                                    {renderIcon({ name: currentIcon, folder: currentIconStyle })}
                                    <h2>{description}</h2>
                                </div>
                                <div className="go-to-category">
                                    <h3>{See_all_in_category[lang]}</h3>
                                    <span>{More[lang]}</span>
                                </div>
                            </a>
                        </Link>
                        <Flex justify="space-between" align-items="center">
                            {main[activeCategory] &&
              Array.isArray(services) &&
              mainServices.length > 0
                                ? mainServices.map((service, i) => {
                                    const imageUrl = service.imageUrl
                                        ? process.env.ASSETS_URL + '/' + service.imageUrl
                                        : '/assets/images/topx-user.jpg';
                                    return (
                                        <Link key={i} href={'/service/' + service.id}>
                                            <a className="service">
                                                {service.imageUrl && (
                                                    <Image
                                                        layout="fill"
                                                        src={imageUrl}
                                                        alt={
                                                            lang === 'ru'
                                                                ? service.titleRU
                                                                : lang === 'uz'
                                                                    ? service.titleUZ
                                                                    : service.titleEN
                                                        }
                                                    />
                                                )}
                                                <div className="service-details">
                                                    <h3>
                                                        {lang === 'ru'
                                                            ? service.titleRU
                                                            : lang === 'uz'
                                                                ? service.titleUZ
                                                                : service.titleEN}
                                                    </h3>
                                                </div>
                                            </a>
                                        </Link>
                                    );
                                })
                                : services.slice(0, 3).map((service, i) => {
                                    const imageUrl = service.imageUrl
                                        ? process.env.ASSETS_URL + '/' + service.imageUrl
                                        : '/assets/images/topx-user.jpg';
                                    return (
                                        <Link key={i} href={'/service/' + service.id}>
                                            <a className="service">
                                                {service.imageUrl && (
                                                    <Image
                                                        layout="fill"
                                                        src={imageUrl}
                                                        alt={
                                                            lang === 'ru'
                                                                ? service.titleRU
                                                                : lang === 'uz'
                                                                    ? service.titleUZ
                                                                    : service.titleEN
                                                        }
                                                    />
                                                )}
                                                <div className="service-details">
                                                    <h3>
                                                        {lang === 'ru'
                                                            ? service.titleRU
                                                            : lang === 'uz'
                                                                ? service.titleUZ
                                                                : service.titleEN}
                                                    </h3>
                                                    <p>{Pros_near_you[lang]}</p>
                                                </div>
                                            </a>
                                        </Link>
                                    );
                                })}
                        </Flex>
                        <Flex justify="center">
                            <Link href="/categories">
                                <a className="all-categories">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.33331 1.99999C1.33331 1.63181 1.63179 1.33333 1.99998 1.33333H6.66665C7.03484 1.33333 7.33331 1.63181 7.33331 1.99999V6.66666C7.33331 7.03485 7.03484 7.33333 6.66665 7.33333H1.99998C1.63179 7.33333 1.33331 7.03485 1.33331 6.66666V1.99999ZM2.66665 2.66666V6H5.99998V2.66666H2.66665Z"
                                            fill="var(--color-flickr-blue)"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.66669 1.99999C8.66669 1.63181 8.96516 1.33333 9.33335 1.33333H14C14.3682 1.33333 14.6667 1.63181 14.6667 1.99999V6.66666C14.6667 7.03485 14.3682 7.33333 14 7.33333H9.33335C8.96516 7.33333 8.66669 7.03485 8.66669 6.66666V1.99999ZM10 2.66666V6H13.3334V2.66666H10Z"
                                            fill="var(--color-flickr-blue)"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.66669 9.33334C8.66669 8.96515 8.96516 8.66667 9.33335 8.66667H14C14.3682 8.66667 14.6667 8.96515 14.6667 9.33334V14C14.6667 14.3682 14.3682 14.6667 14 14.6667H9.33335C8.96516 14.6667 8.66669 14.3682 8.66669 14V9.33334ZM10 10V13.3333H13.3334V10H10Z"
                                            fill="var(--color-flickr-blue)"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.33331 9.33334C1.33331 8.96515 1.63179 8.66667 1.99998 8.66667H6.66665C7.03484 8.66667 7.33331 8.96515 7.33331 9.33334V14C7.33331 14.3682 7.03484 14.6667 6.66665 14.6667H1.99998C1.63179 14.6667 1.33331 14.3682 1.33331 14V9.33334ZM2.66665 10V13.3333H5.99998V10H2.66665Z"
                                            fill="var(--color-flickr-blue)"
                                        />
                                    </svg>

                                    <span>{All_services[lang]}</span>
                                </a>
                            </Link>
                        </Flex>
                    </div>
                </Container>
            </CategoriesCss>
        </LazyLoad>
    );
};

export default memo(Categories);
