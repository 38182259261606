import { Rule } from 'rc-field-form/lib/interface';
import { this_field_is_required } from '../../helpers/dictionary';
import { stringAsNumberValidator } from './SignupForm/utils';

export const phoneNumberValidation: Rule[] = [
    { required: true },
    { len: 9 },
    () => ({
        validator: stringAsNumberValidator,
    }),
];

export const requiredFieldValidationMessages = (lang: string) => ({
    required: this_field_is_required[lang]
});

export const codeValidationRule: Rule[] = [
    { required: true },
    { len: 7 },
    { pattern: new RegExp(/^\d{3}-\d{3}$/), message: '000-000' }
];