import NextImage, { ImageProps as NextImageProps } from 'next/image';
import React from 'react';
import { buildImageUrl as cloudinarybuildImageUrl } from 'cloudinary-build-url';

interface ImageLoaderProps {
    src: string;
    width?: number;
    quality?: number;
}

export const buildImageUrl = ({ src, width = 1024, quality = 75 }: ImageLoaderProps) => cloudinarybuildImageUrl(
    src,
    {
        cloud: {
            cloudName: 'topx',
        },
        transformations: {
            quality,
            resize: {
                type: 'scale',
                width,
            },
        },
    },
);

const loader = ({ src, width = 1024, quality = 75 }: ImageLoaderProps) => {
    return buildImageUrl({ src, quality, width });
};


const CloudinaryImage = (props: NextImageProps) => {
    return (
        <NextImage
            quality={70}
            priority={true}
            loader={loader}
            {...props}
        />
    );
};

export default CloudinaryImage;
