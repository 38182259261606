import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import Logo from '../../svgs/logo.svg';
import { About, Agreement, Privacy_Policy } from '../../helpers/dictionary';
import { useSelector } from 'react-redux';

const FooterCss = styled.footer`
  display: none;
  @media (min-width: 768px) {
    display: block;
    padding: 3.4rem 1.6rem;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        svg {
          width: 8.2rem;
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          margin-left: 6.4rem;
          li {
            font-size: 1.5rem;
            margin-right: 3.2rem;
            a {
              color: #565459;
            }
          }
        }
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
`;

const Footer = ({ lang }) => {
    const showState = useSelector((state) => state.settings.showFooter);
    if (showState) {
        return (
            <FooterCss>
                <div className="content">
                    <div className="left">
                        <Link href="/">
                            <a>
                                <Logo />
                            </a>
                        </Link>
                        <ul>
                            <li>
                                <a href="#">{Privacy_Policy[lang]}</a>
                            </li>
                            <li>
                                <a href="#">{Agreement[lang]}</a>
                            </li>
                            <li>
                                <a href="#">{About[lang]}</a>
                            </li>
                        </ul>
                    </div>
                    <p className="right">© {new Date().getFullYear()} TopX</p>
                </div>
            </FooterCss>
        );
    } else {
        return null;
    }
};

export default Footer;
