import React, { useEffect, useState } from 'react';
import { Tabs, Button, Collapse, Form, Input , Card, Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { RightOutlined } from '@ant-design/icons';
import { COUNTRY_CODE } from 'components/forms/constants';
import { AccountIn, addEmail, Coming_Soon, ContactInfo, CPass, DeactivateInfo, DLMA, EmailInfo, Joined, legalName, LoginNum, NewMail, NotifiCationInfo, NPas, PasswordInfo, PaymentInfo, Profile, PSet, QuotesInfo, SaveI, Settings, YourEmail, yourfullName } from 'helpers/dictionary';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'components/styles/Container';
import { ACCOUNT, BACKSET, NOTIFICATION, PAYMENTS, QUOTES } from 'actions/types';
import { useRouter } from 'next/router';
import { requiredFieldValidationMessages } from 'components/forms/validation';
import { loadUser, logout } from 'actions/auth';
import axios from '../../utils/axios';
import moment from 'moment';


const { Panel } = Collapse;
const { TabPane } = Tabs;

export const DeskTopView = () => {
    const lang = useSelector((state) => state.settings.lang);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const router = useRouter();
    return (
        <Container style={{ marginTop: '5%' }}>
            <h6 style={{ marginRight: '24px', marginLeft: '24px', fontWeight: '700', fontSize: '20px', textAlign: auth.user && auth.user.status === 'pro' ? 'left' : 'left' }}>{PSet[lang]}</h6>
            <Row style={{ marginRight: '24px', marginLeft: '24px' }}>
                <Col span={7}>
                    <Card onClick={() => {
                        dispatch({
                            type: ACCOUNT
                        }); router.push({
                            pathname: '/profile/information'
                        });
                    }} style={{ textAlign: 'center', width:'250px',  paddingTop:'78px',height: '250px' }} className="cardInfo an-card" bordered={false}>
                        <Row style={{position:'relative',top:'-15px'}}>
                            <Col span={24}>
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.41596 34.018L7.146 34.5263L7.51779 34.9657C10.6626 38.6824 15.7836 41.1 21 41.1C26.1858 41.1 31.9022 38.7042 35.0655 34.9657L35.4373 34.5263L35.1674 34.018C34.3307 32.4426 32.8473 30.3708 31.0958 28.682C29.3803 27.0279 27.2305 25.5694 25.0645 25.5694C24.7676 25.5694 24.4808 25.6187 24.2158 25.6993L24.208 25.7017L24.2002 25.7043C23.1737 26.0385 22.1034 26.2468 21 26.2468C19.8974 26.2468 18.8353 26.0388 17.798 25.7037L17.7911 25.7014L17.7842 25.6993C17.5192 25.6187 17.2324 25.5694 16.9355 25.5694C14.7502 25.5694 12.742 27.0487 11.1687 28.7093C9.56207 30.4053 8.23615 32.4736 7.41596 34.018ZM0.85 21C0.85 9.86864 9.86864 0.85 21 0.85C32.1314 0.85 41.15 9.86864 41.15 21C41.15 32.1314 32.1314 41.15 21 41.15C9.86864 41.15 0.85 32.1314 0.85 21ZM29.3016 15.5806C29.3016 10.9959 25.5848 7.27903 21 7.27903C16.4152 7.27903 12.6984 10.9959 12.6984 15.5806C12.6984 20.1654 16.4152 23.8823 21 23.8823C25.5848 23.8823 29.3016 20.1654 29.3016 15.5806Z" stroke="#565459" strokeWidth="1.7" />
                                </svg></Col>
                            <Col span={24} style={{marginTop:'1rem'}}>
                                <span style={{ fontSize: '18px', fontWeight: '700', }}>{AccountIn[lang]}</span>
                            </Col>
                        </Row>

                    </Card>
                </Col>
                <Col span={1}></Col>
                <Col span={7}>

                    <Card onClick={() => {
                        dispatch({
                            type: NOTIFICATION
                        }); router.push({
                            pathname: '/profile/information'
                        });
                    }} className="cardInfo an-card" style={{ textAlign: 'center', width:'250px',  paddingTop:'78px',height: '250px' }} bordered={false}>
                        <Row style={{position:'relative',top:'-15px'}}>
                            <Col span={24}>
                                <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 41C24.5 41 24.375 34.3553 24.375 34.3553H11.625C11.625 34.3553 11.5 41 18 41ZM34.3465 30.0487C32.8803 28.3842 30.1368 25.8801 30.1368 17.6776C30.1368 11.4476 26.0021 6.46032 20.427 5.23676V3.56579C20.427 2.14899 19.3403 1 18 1C16.6597 1 15.573 2.14899 15.573 3.56579V5.23676C9.99788 6.46032 5.86325 11.4476 5.86325 17.6776C5.86325 25.8801 3.11973 28.3842 1.65349 30.0487C1.19813 30.5659 0.996258 31.1841 1.00005 31.7895C1.0084 33.1044 1.98514 34.3553 3.43621 34.3553H32.5638C34.0149 34.3553 34.9924 33.1044 34.9999 31.7895C35.0037 31.1841 34.8019 30.5651 34.3465 30.0487Z" stroke="black" strokeWidth="1.7" />
                                </svg></Col>
                            <Col span={24} style={{marginTop:'1rem'}}>
                                <span style={{ fontSize: '18px', fontWeight: '700',marginTop:'1rem' }}>{NotifiCationInfo[lang]}</span></Col>
                        </Row>

                    </Card>
                </Col>
                <Col span={1}></Col>
                <Col span={7}>
                    {auth.user && auth.user.status === 'pro' &&
                        <Card onClick={() => {
                            dispatch({
                                type: PAYMENTS
                            }); router.push({
                                pathname: '/profile/information'
                            });
                        }} className="cardInfo an-card" style={{ textAlign: 'center', width:'250px',  paddingTop:'78px',height: '250px' }} bordered={false}>
                            <Row style={{position:'relative',top:'-15px'}}>
                                <Col span={24}>
                                    <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0H42C43.5913 0 45.1174 0.632141 46.2426 1.75736C47.3679 2.88258 48 4.4087 48 6V30C48 31.5913 47.3679 33.1174 46.2426 34.2426C45.1174 35.3679 43.5913 36 42 36H6C4.4087 36 2.88258 35.3679 1.75736 34.2426C0.632141 33.1174 0 31.5913 0 30V6ZM5.5 2.5C4.70435 2.5 3.94129 2.81607 3.37868 3.37868C2.81607 3.94129 2.5 4.70435 2.5 5.5V8.5H45.5V5.5C45.5 4.70435 45.1839 3.94129 44.6213 3.37868C44.0587 2.81607 43.2957 2.5 42.5 2.5H5.5ZM45.5 13.5H2.5V30C2.5 30.7957 2.81607 31.5587 3.37868 32.1213C3.94129 32.6839 4.70435 33.5 5.5 33.5H42C42.7957 33.5 44.0587 32.6839 44.6213 32.1213C45.1839 31.5587 45.5 30.7957 45.5 30V13.5Z" fill="#565459" />
                                    </svg>
                                </Col>
                                <Col span={24} style={{marginTop:'1rem'}}>
                                    <span style={{ fontSize: '18px', fontWeight: '700',marginTop:'1rem' }}>{PaymentInfo[lang]}</span></Col>
                            </Row>

                        </Card>}
                </Col>
                <Col span={1}></Col>
            </Row>
            <Row style={{ marginRight: '24px', marginLeft: '24px', marginTop: '20px' }}>
                <Col span={7}>
                    {auth.user && auth.user.status === 'pro' &&
                        <Card onClick={() => {
                            dispatch({
                                type: QUOTES
                            }); router.push({
                                pathname: '/profile/information'
                            });
                        }} style={{ textAlign: 'center', width:'250px',  paddingTop:'78px',height: '250px' }} className="cardInfo an-card" bordered={false}>
                            <Row style={{position:'relative',top:'-15px'}}>
                                <Col span={24} >
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.85 20.1891V3.84375C0.85 2.19032 2.19032 0.85 3.84375 0.85H20.1891C20.9831 0.850004 21.7446 1.16542 22.306 1.72686L22.9043 1.12864L22.306 1.72686L39.2732 18.694C40.4423 19.8631 40.4423 21.7586 39.2732 22.9278L22.9278 39.2732C21.7586 40.4423 19.8631 40.4423 18.694 39.2732L1.72686 22.306L1.12864 22.9043L1.72686 22.306C1.16542 21.7446 0.850004 20.9831 0.85 20.1891ZM11.0811 6.38733C8.48877 6.38733 6.38733 8.48877 6.38733 11.0811C6.38733 13.6734 8.48877 15.7748 11.0811 15.7748C13.6734 15.7748 15.7748 13.6734 15.7748 11.0811C15.7748 8.48877 13.6734 6.38733 11.0811 6.38733Z" stroke="#565459" strokeWidth="1.7" />
                                    </svg>
                                </Col>
                                <Col span={24} style={{marginTop:'1rem'}}>
                                    <span style={{ fontSize: '18px', fontWeight: '700',marginTop:'1rem' }}>{QuotesInfo[lang]}</span>
                                </Col>
                            </Row>

                        </Card>}
                </Col>
                <Col span={17}></Col>

            </Row>

        </Container>
    );
};

const Contact = () => {
    const lang = useSelector((state) => state.settings.lang);
    const user = useSelector((state) => state.auth.user);
    const [newProfile, setNewProfile] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [telegramUsername, setTelegramUsername] = useState('');
    const [error, setError] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName=='null'?'':user.lastName==null?'':user.lastName);
            setEmail(user.email);
            setPhoneNumber(user.phone);
            setTelegramUsername(user.tgusername);
            setNewProfile(user.imageUrl);
        }
    }, [user]);

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {

        e.preventDefault();
        try{
            const data = new FormData();

            data.append('image', newProfile);
            data.append('firstName', firstName);
            data.append('lastName', lastName);
            data.append('phone', phoneNumber);
            data.append('email', email);
            data.append('tgusername', telegramUsername);

            await axios.put(`/api/v1/user/${user.id}`, data, {
                withCredentials: true,
                headers: {
                    accept: 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            });



            setSuccess('Changes Saved !');
            dispatch(loadUser());
            setError(null);
            setTimeout(function () { setSuccess(null); }, 5000);
        } catch (error) {
            setLoading(false);
            setError('Something Went Wrong !');
        }
    };

    return (
        <Form
            validateMessages={requiredFieldValidationMessages(lang)}
            validateTrigger={['onBlur', 'onSubmit']}
            layout="vertical"


        >

            <Form.Item style={{ fontWeight: '600', fontSize: '15px' }} rules={[{ required: true }]}
                label={yourfullName[lang]}>
                <Input style={{height:'44px'}}value={firstName + '' + lastName} onChange={(e) => { setFirstName(e.target.value); setLastName(''); }} placeholder="input placeholder" />

            </Form.Item>
            <span style={{ textAlign: 'right' }}>
                <p style={{ fontSize: '13px', fontWeight: '400', textAlign: 'right', marginTop: '-1.8rem' }}>{legalName[lang]}</p>
            </span>
            <Form.Item
                style={{ fontWeight: '600', fontSize: '15px',height:'44px' }}
                label={phoneNumber[lang]}
                hasFeedback
                validateStatus={phoneNumber.length > 9 ? 'error' : 'success'}
                help={phoneNumber.length > 9 ? 'Phone Number should be of 9 digit' : ''}

            >
                <Input  className="ph" style={{height:'44px'}} value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value); }} addonBefore={`+${COUNTRY_CODE}`} />

            </Form.Item>
            <span style={{ textAlign: 'right', }}>
                <p style={{ fontSize: '13px', fontWeight: '400', textAlign: 'right', marginTop: phoneNumber.length > 9 ? '1.8rem' : '-2.1rem' }}>{LoginNum[lang]}</p>
            </span>

            <Form.Item    hasFeedback style={{display:error? '': success? '':'none' }}

                help={error ?error : success?success:''}></Form.Item>

            <Form.Item style={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'var(--color-flickr-blue)', color: 'white',borderRadius:'4px',height:'42px' }}>{SaveI[lang]}</Button>
            </Form.Item>
        </Form>);
};
const Password = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);

    const lang = useSelector((state) => state.settings.lang);

    const handleSubmit = async (e) => {
        e.preventDefault();


        if (newPassword.length < 4) {
            return setError('Password should be at least 4 letters.');
        }

        setLoading(true);

        try {
            await axios.post(process.env.BACKEND_URL + '/api/v1/auth', {
                new_password: newPassword,
                old_password: oldPassword,
            });

            setSuccess('Password  changed');
            setError(null);
            setTimeout(function () { setSuccess(null); }, 5000);
        } catch (error) {
            setLoading(false);
            setError('Wrong Password !');
        }

    };

    return (
        <Form

            layout="vertical"
            validateMessages={requiredFieldValidationMessages(lang)}
            validateTrigger={['onBlur', 'onSubmit']}

        >

            <Form.Item hasFeedback
                validateStatus={error ? 'error' : success ? 'success' : null}
                style={{ fontWeight: '600', fontSize: '15px' }} label={CPass[lang]}>
                <Input style={{height:'44px'}}type="password" onChange={(e) => { setOldPassword(e.target.value); }} placeholder={CPass[lang]} />

            </Form.Item>

            <Form.Item
                style={{ fontWeight: '600', fontSize: '15px' }}
                hasFeedback
                validateStatus={error ? 'error' : success ? 'success' : null}
                help={error ? error : success}
                label={NPas[lang]}

            >
                <Input style={{height:'44px'}}type="password" onChange={(e) => { setNewPassword(e.target.value); }} placeholder={NPas[lang]} />

            </Form.Item>


            <Form.Item style={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'var(--color-flickr-blue)', color: 'white',borderRadius:'4px',height:'42px' }}>{SaveI[lang]}</Button>
            </Form.Item>
        </Form>);
};
const Email = () => {
    const lang = useSelector((state) => state.settings.lang);
    const user = useSelector((state) => state.auth.user);
    const [newProfile, setNewProfile] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [telegramUsername, setTelegramUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setPhoneNumber(user.phone);
            setTelegramUsername(user.tgusername);
            setNewProfile(user.imageUrl);
        }
    }, [user]);

    const dispatch = useDispatch();



    const handleSubmit = async (e) => {

        e.preventDefault();


        try {
            await axios.post(process.env.BACKEND_URL + '/api/v1/auth', {
                new_password: oldPassword,
                old_password: oldPassword,
            });
            const data = new FormData();

            data.append('image', newProfile);
            data.append('firstName', firstName);
            data.append('lastName', lastName);
            data.append('phone', phoneNumber);
            data.append('email', email);
            data.append('tgusername', telegramUsername);



            await axios.put(`/api/v1/user/${user.id}`, data, {
                withCredentials: true,
                headers: {
                    accept: 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
            });

            setSuccess('Email Updated');
            setError(null);
            setTimeout(function () { setSuccess(null); }, 5000);

            dispatch(loadUser());
        } catch (error) {

            setError('Password not matched! Please enter correct password');
        }

    };
    return (
        <Form

            layout="vertical"
            validateMessages={requiredFieldValidationMessages(lang)}
            validateTrigger={['onBlur', 'onSubmit']}

        >
            <Form.Item style={{ fontWeight: '600', fontSize: '15px', textAlign: 'left' }}  >
                <h6 style={{ fontWeight: '600', fontSize: '15px' }} >{YourEmail[lang]}:<span style={{ fontSize: '13px', fontWeight: '400', textAlign: 'right', marginLeft: '1rem' }}>{user&& user &&user?.email=='null'?addEmail[lang]: user?.email?user.email : addEmail[lang]}</span></h6>

            </Form.Item>
            <Form.Item hasFeedback validateStatus={error ? 'error' : success ? 'success' : null} style={{ fontWeight: '600', fontSize: '15px' }} label={CPass[lang]} rules={[{ required: true }]}>
                <Input style={{height:'44px'}}type="password" onChange={(e) => { setOldPassword(e.target.value); }} placeholder={CPass[lang]} />

            </Form.Item>

            <Form.Item

                hasFeedback
                validateStatus={error ? 'error' : success ? 'success' : null}
                help={error ? 'Password not matched! Please enter correct password' : success}
                style={{ fontWeight: '600', fontSize: '15px' }}

                label={NewMail[lang]}

            >
                <Input style={{height:'44px'}}onChange={(e) => { setEmail(e.target.value); }}  type="email" />

            </Form.Item>


            <Form.Item style={{ textAlign: 'right' }} rules={[{ required: true }]}>
                <Button onClick={handleSubmit} style={{ backgroundColor: 'var(--color-flickr-blue)', color: 'white',borderRadius:'4px',height:'42px' }}>{SaveI[lang]}</Button>
            </Form.Item>
        </Form>);
};
const Info = () => {
    const auth = useSelector((state) => state.auth);
    const lang = useSelector((state) => state.settings.lang);
    const profile = useSelector((state) => state.profile);
    const router = useRouter();
    const [disable, setDisable] = useState();
    const dispatch = useDispatch();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 780px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1150px)' });
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 780px)'
    });
    const Colappse=  useMediaQuery({
        query: '(max-device-width: 990px)'
    });
    const colp=  useMediaQuery({
        query: '(max-width: 880px)'
    });

    useEffect(() => {
        if ((typeof window !== 'undefined' && window.innerWidth < 780) && profile.back === true) {
            router.push({
                pathname:  auth.user.status=='pro'?'/profile/settings':'/client/profile'
            });
        }
    }, []);

    const handleDeactivate = async () => {
        const data = new FormData();
        data.append('status','deactivate');
        await axios.put(`/api/v1/user/deactivate/${auth&& auth.user && auth.user.id}`,data, {
            withCredentials: true,
            headers: {
                accept: 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
        });



        dispatch(loadUser());
        dispatch(logout());


    };

    return (
        <>
            {isTabletOrMobile ? (
                profile.notifications ? <><div >
                    <Button onClick={() => {
                        dispatch({
                            type: BACKSET
                        });
                        router.push({
                            pathname:  auth.user.status=='pro'?'/profile/settings':'/client/profile'
                        });
                    }} style={{
                        position: 'relative', top: '29px', paddingTop: '-9px',
                        paddingBottom: '5px', borderBottom: isTabletOrMobileDevice ? 'none' : '1px solid #d3d3d3', marginLeft: isTabletOrMobileDevice ? '2%' : '10%', textAlign: 'left', fontWeight: '500', fontSize: '13px', color: '#6A3CBC', border: isTabletOrMobileDevice ? 'none' : ''
                    }} className="ant-tabs-nav btn1"><svg width="10" height="15" style={{ position:'relative', top:'2px',marginLeft: '-9px' }} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.725 2.225L8.25 0.75L0 9L8.25 17.25L9.725 15.775L2.95 9L9.725 2.225Z" fill="#6A3CBC" />
                        </svg>
                        {' '}
                        {auth.user.status=='pro'?Settings[lang]:Profile[lang]}
                    </Button>
                    <h6 style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>{NotifiCationInfo[lang]}</h6>
                </div>
                </> :
                    profile.payments ? <><div >
                        <Button onClick={() => {
                            dispatch({
                                type: BACKSET
                            });
                            router.push({
                                pathname:  auth.user.status=='pro'?'/profile/settings':'/client/profile'
                            });
                        }} style={{
                            position: 'relative', top: '29px', paddingTop: '-9px',
                            paddingBottom: '5px', borderBottom: isTabletOrMobileDevice ? 'none' : '1px solid #d3d3d3', marginLeft: isTabletOrMobileDevice ? '2%' : '10%', textAlign: 'left', fontWeight: '500', fontSize: '13px', color: '#6A3CBC', border: isTabletOrMobileDevice ? 'none' : ''
                        }} className="ant-tabs-nav btn1"><svg width="10" height="15" style={{ position:'relative', top:'2px',marginLeft: '-9px' }} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.725 2.225L8.25 0.75L0 9L8.25 17.25L9.725 15.775L2.95 9L9.725 2.225Z" fill="#6A3CBC" />
                            </svg>
                            {' '}
                            {auth.user.status=='pro'?Settings[lang]:Profile[lang]}
                        </Button>
                        <h6 style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>{PaymentInfo[lang]}</h6>

                    </div>
                    </> :
                        profile.quotes ? <><div >
                            <Button onClick={() => {
                                dispatch({
                                    type: BACKSET
                                });
                                router.push({
                                    pathname:  auth.user.status=='pro'?'/profile/settings':'/client/profile'
                                });
                            }} style={{
                                position: 'relative', top: '29px', paddingTop: '-9px',
                                paddingBottom: '5px', borderBottom: isTabletOrMobileDevice ? 'none' : '1px solid #d3d3d3', marginLeft: isTabletOrMobileDevice ? '2%' : '10%', textAlign: 'left', fontWeight: '500', fontSize: '13px', color: '#6A3CBC', border: isTabletOrMobileDevice ? 'none' : ''
                            }} className="ant-tabs-nav btn1"><svg width="10" height="15" style={{ position:'relative', top:'2px',marginLeft: '-9px' }} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.725 2.225L8.25 0.75L0 9L8.25 17.25L9.725 15.775L2.95 9L9.725 2.225Z" fill="#6A3CBC" />
                                </svg>
                                {' '}
                                {auth.user.status=='pro'?Settings[lang]:Profile[lang]}
                            </Button>
                            <h6 style={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>{QuotesInfo[lang]}</h6>
                        </div>
                        </> :
                            profile.account ? <> <div >
                                <Button onClick={() => {
                                    dispatch({
                                        type: BACKSET
                                    });
                                    router.push({
                                        pathname:  auth.user.status=='pro'?'/profile/settings':'/client/profile'
                                    });
                                }} style={{
                                    position: 'relative', top: '29px', paddingTop: '-9px',
                                    paddingBottom: '5px', borderBottom: isTabletOrMobileDevice ? 'none' : '1px solid #d3d3d3', marginLeft: isTabletOrMobileDevice ? '2%' : '10%', textAlign: 'left', fontWeight: '500', fontSize: '19px', color: '#6A3CBC', border: isTabletOrMobileDevice ? 'none' : ''
                                }} className="ant-tabs-nav btn1"><svg width="10" height="15" style={{ position:'relative',marginRight: '4px', top:'2px',marginLeft: '-9px' }} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.725 2.225L8.25 0.75L0 9L8.25 17.25L9.725 15.775L2.95 9L9.725 2.225Z" fill="#6A3CBC" />
                                    </svg>
                                    {' '}
                                    {auth.user.status=='pro'?Settings[lang]:Profile[lang]}
                                </Button>
                                <h6 style={{ fontSize: '19px', fontWeight: '500', textAlign: 'center' }}>{AccountIn[lang]}</h6>
                                <Collapse onChange={(e) => { setDisable(e); }} accordion expandIconPosition={'right'}   >
                                    <Panel style={{ marginBottom: '0px', background: isTabletOrMobile ? 'white' : '',borderRadius:isTabletOrMobile ? '10px' : '',margin:isTabletOrMobile ? '10px' : '' }} header={<><div><div >{ContactInfo[lang]} :</div><div style={{ display: disable == 1 ? 'none' : '', color: '#858A8F', fontSize: '14px', fontWeight: '400' }}> {auth && auth.user &&auth.user.firstName} {auth && auth.user && auth.user.lastName=='null'?'':auth?.user?.lastName?auth.user.lastName:''}, {auth && auth.user && auth.user.phone}</div></div></>} key="1">
                                        <Contact />
                                    </Panel>
                                    <Panel style={{ marginBottom: '0px', background: isTabletOrMobile ? 'white' : '',borderRadius:isTabletOrMobile ? '10px' : '',margin:isTabletOrMobile ? '10px' : '' }} header={<><div><div >{EmailInfo[lang]} :</div><div style={{ display: disable == 2 ? 'none' : '', color: '#858A8F', fontSize: '14px', fontWeight: '400' }}>{auth && auth.user &&auth?.user?.email=='null'?addEmail[lang]: auth?.user?.email?auth.user.email : addEmail[lang]}</div></div></>} key="2">
                                        <Email />
                                    </Panel>
                                    <Panel style={{ marginBottom: '0px', background: isTabletOrMobile ? 'white' : '',borderRadius:isTabletOrMobile ? '10px' : '',margin:isTabletOrMobile ? '10px' : '' }} header={<><div><div >{PasswordInfo[lang]} :</div><div style={{ display: disable == 3 ? 'none' : '', color: '#858A8F', fontSize: '14px', fontWeight: '400' }}>...........</div></div></>} key="3">
                                        <Password />
                                    </Panel>
                                    <Panel style={{ marginBottom: '0px', background: isTabletOrMobile ? 'white' : '',borderRadius:isTabletOrMobile ? '10px' : '',margin:isTabletOrMobile ? '10px' : '' }} header={DeactivateInfo[lang]} key="4">
                                        <Button onClick={handleDeactivate} style={{
                                            background: 'var(--color-jet)', color: 'white', padding: '12px 28px', width: '191px',
                                            height: '42px', borderRadius: '4px'
                                        }}>{DLMA[lang]}</Button>
                                    </Panel>
                                </Collapse>

                                <div style={{padding:'12px',fontSize:'15px',fontWeight:'600',textAlign:'center'}}>{Joined[lang]} : {moment(auth.user.status === 'pro'?auth.user.createdAt:auth.user.createdAt).format('DD.MM.YY')}</div>
                            </div></> : null) :
                <>
                    <Button onClick={() => {
                        dispatch({
                            type: BACKSET
                        });
                        router.push({
                            pathname:  auth.user.status=='pro'?'/profile/settings':'/client/profile'
                        });
                    }} style={{
                        display: isTabletOrMobile ? 'none' : '', paddingLeft:'38px',height:'60px',
                        paddingBottom: '5px', borderBottom: '1px solid #d3d3d3', marginLeft: isTabletOrMobileDevice ? '2%' : '10%', textAlign: 'left', fontWeight: '600', fontSize: '20px', color: '#6A3CBC', border: isTabletOrMobileDevice ? 'none' : ''
                    }} className="ant-tabs-nav btn1"><svg width="10" height="18" style={{ marginLeft: '-9px',position:'relative', top:'2px',left:'-2px'  }} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.725 2.225L8.25 0.75L0 9L8.25 17.25L9.725 15.775L2.95 9L9.725 2.225Z" fill="#6A3CBC" />
                        </svg>
                        {auth.user.status=='pro'?Settings[lang]:Profile[lang]}
                    </Button>
                    <Tabs defaultActiveKey={profile.account ? '1' : profile.notifications ? '2' : profile.quotes ? '4' : profile.payments ? '3' : '1'} icon={<RightOutlined />} type="card" tabPosition={isTabletOrMobile ? 'top' : isTabletOrMobileDevice ? 'top' : 'left'} style={{ height: '100%', marginLeft: isTabletOrMobile ? '2%' : isTabletOrMobileDevice ? '2%' : '10%' }}>

                        <TabPane
                            tab={
                                <div>
                                    <span>

                                        {AccountIn[lang]}

                                    </span>
                                    <RightOutlined style={{ display: isTabletOrMobile ? 'none' : '', position: 'fixed',left:'21.5rem',  }} />
                                </div>
                            } key="1" >
                            <div style={{width: Colappse?'490px': colp?'400px':'575px',marginLeft: isTablet?'2%':'10%' }}>
                                <h6 style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}>{AccountIn[lang]}</h6>
                                <Collapse onChange={(e) => { setDisable(e); }} accordion expandIconPosition={'right'}   >
                                    <Panel style={{ marginBottom: '20px', background: isTabletOrMobile ? 'white' : '' }} header={<><div style={{width:'50%'}}>{ContactInfo[lang]}</div><div style={{ display: disable == 1 ? 'none' : '', color: '#858A8F', fontSize: '14px', fontWeight: '400' }}> {auth && auth.user &&auth.user.firstName} {auth && auth.user && auth.user.lastName=='null'?'':auth?.user?.lastName?auth.user.lastName:''}, {auth && auth.user && auth.user.phone}</div></>} key="1">
                                        <Contact />
                                    </Panel>
                                    <Panel style={{ marginBottom: '20px', background: isTabletOrMobile ? 'white' : '' }} header={<><div style={{width:'50%'}}>{EmailInfo[lang]}</div><div style={{ display: disable == 2 ? 'none' : '', color: '#858A8F', fontSize: '14px', fontWeight: '400' }}>{auth && auth.user &&auth?.user?.email=='null'?addEmail[lang]: auth?.user?.email?auth.user.email : addEmail[lang]}</div></>} key="2">
                                        <Email />
                                    </Panel>
                                    <Panel style={{ marginBottom: '20px', background: isTabletOrMobile ? 'white' : '' }} header={<><div style={{width:'50%'}}>{PasswordInfo[lang]}</div><div style={{ display: disable == 3 ? 'none' : '', color: '#858A8F', fontSize: '14px', fontWeight: '400' }}>...........</div></>} key="3">
                                        <Password />
                                    </Panel>
                                    <Panel style={{ marginBottom: '20px', background: isTabletOrMobile ? 'white' : '' }} header={DeactivateInfo[lang]} key="4">
                                        <Button onClick={handleDeactivate} style={{
                                            background: 'var(--color-jet)', color: 'white', padding: '12px 28px', width: '191px',
                                            height: '42px', borderRadius: '4px'
                                        }}>{DLMA[lang]}</Button>
                                    </Panel>
                                </Collapse>
                                <div style={{padding:'12px',fontSize:'15px',fontWeight:'600',textAlign:'center'}}>{Joined[lang]} : {moment(auth.user.status === 'pro'?auth.user.createdAt:auth.user.createdAt).format('DD.MM.YY')}</div>
                            </div>
                        </TabPane>
                        <TabPane tab={
                            <div>
                                <span>

                                    {NotifiCationInfo[lang]}

                                </span>
                                <RightOutlined style={{ display: isTabletOrMobile ? 'none' : '', position: 'fixed',left:'21.5rem'}} />
                            </div>
                        } key="2"  >
                            <h6 style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}> {NotifiCationInfo[lang]}</h6>
                            <h6 style={{ fontSize: '16px', fontWeight: '700',textAlign: 'center',marginTop:'1rem',color:'var(--color-french-violet)' }}> {Coming_Soon[lang]}</h6>
                        </TabPane>
                        {auth.user && auth.user.status === 'pro' &&
                            <TabPane tab={
                                <div>
                                    <span>
                                        {PaymentInfo[lang]}

                                    </span>
                                    <RightOutlined style={{ display: isTabletOrMobile ? 'none' : '', position: 'fixed',left:'21.5rem',  }} />
                                </div>
                            } key="3" >
                                <h6 style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}> {PaymentInfo[lang]}</h6>
                                <h6 style={{ fontSize: '16px', fontWeight: '700',color:'var(--color-french-violet)', textAlign: 'center',marginTop:'1rem' }}> {Coming_Soon[lang]}</h6>
                            </TabPane>}
                        {auth.user && auth.user.status === 'pro' &&
                            <TabPane tab={
                                <div>
                                    <span>

                                        {QuotesInfo[lang]}

                                    </span>
                                    <RightOutlined style={{ display: isTabletOrMobile ? 'none' : '', position: 'fixed',left:'21.5rem', }} />
                                </div>
                            } key="4"  >
                                <h6 style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}>{QuotesInfo[lang]}</h6>
                                <h6 style={{ fontSize: '16px', fontWeight: '700',color:'var(--color-french-violet)', textAlign: 'center',marginTop:'1rem' }}> {Coming_Soon[lang]}</h6>
                            </TabPane>}

                    </Tabs>
                </>
            }
        </>

    );
};

export default Info;