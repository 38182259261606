import React, {  useState } from 'react';
import { Row, Typography , Modal } from 'antd';
import { motion } from 'framer-motion';

import {
    REGISTER_FAIL,
    SET_AUTH_LOADING,
    USER_LOADED,
} from '../../actions/types';
import { validateNumberAndSignUp } from '../../api';
import { NoBreakSpace } from '../../helpers/constants';
import {
    Already_have_an_account,
    Create_Account,
    Dont_have_an_account,
    Log_in,
    Sign_up,
    Feedback, submitR } from '../../helpers/dictionary';

import { SignupForm } from '../forms';
import { LoginForm } from '../forms/LoginForm';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utils/axios';

import { StarRating } from './StarRating';
import { useTranslation } from 'react-i18next';
import { FormWrapper, RateProTitle, ReviewWrapper } from './style';
import TextArea from 'antd/lib/input/TextArea';
import { StyledButton } from '../profile/style';
import { Pro } from '../../shared';



interface ReviewProps {
    pro: Pro;
}

const Review = ({ pro }: ReviewProps, link: boolean) => {
    const { t } = useTranslation();
    const [starsClicked, setStartsClicked] = useState(0);
    const [feedbackText, setFeedbackText] = useState('');
    const [active, setActive] = useState(0);

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [login, setLogin] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const dispatch = useDispatch();
    const router = useRouter();


    const handleStarClick = (rating: number) => {
        if (rating === starsClicked) {
            return setStartsClicked(0);
        }
        setStartsClicked(rating);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lang = useSelector((state: any) => state.settings.lang as 'eng' | 'ru' | 'uz');

    const auth = useSelector((state: any) => state.auth);

    const sendReview = async () => {
        setLoading(true);
        try {
            if (auth?.user) {
                await axios.post('/api/v1/pro/rate', {
                    rate: starsClicked,
                    comment: feedbackText,
                    proId: router.query.pId,
                    serviceId: router.query.sId,
                });
                setVisible(false);
                setActive(0);

            } else {
                setActive(1);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            setActive(1);
        }

        if (auth.user) {
            setLoading(false);
            setVisible(true);
            setTimeout(() => {
                if (link) {
                    router.replace(`/`);
                } else {
                    router.replace(`/customer/inbox/${router.query.id}`);
                }

            }, 3000);
        }

    };

    const disableSubmit = !starsClicked || feedbackText.trim().length === 0;

    const handleLogin = async ({ password, countryCode, phone }) => {
        try {
            dispatch({
                type: SET_AUTH_LOADING,
                payload: true,
            });

            await axios({
                method: 'post',
                url: process.env.BACKEND_URL + '/api/v1/auth/login',
                data: {
                    phone,
                    countryCode,
                    password,
                },
            });

            dispatch({
                type: SET_AUTH_LOADING,
                payload: false,
            });

            await axios.get(
                process.env.BACKEND_URL + '/api/v1/auth/login'
            ).then((res) => {

                if (res.data?.status && res.data?.status === 'pro') {
                    axios.get(`/api/v1/pro`).then((r) => {
                        const payload = {
                            user: res.data,
                            pro: r.data,
                        };

                        dispatch({
                            type: USER_LOADED,
                            payload,
                        });
                    });



                } else {
                    dispatch({
                        type: USER_LOADED,
                        payload: {
                            user: res.data,
                        },
                    });
                }
            });


            // const messagesData = await axios.get('/api/v1/message/user');

            // dispatch({
            //   type: MESSAGES_LOADED,
            //   payload: messagesData.data,
            // });


            await axios.post('/api/v1/pro/rate', {
                rate: starsClicked,
                comment: feedbackText,
                proId: router.query.pId,
                serviceId: router.query.sId,
            });
            setLoading(false);
            setVisible(true);
            setTimeout(() => {

                router.replace(`/`);
            }, 3000);

        } catch (error) {
            const { response } = error;

            const errors = response.data || [];

            dispatch({
                type: SET_AUTH_LOADING,
                payload: false,
            });

            dispatch({
                type: REGISTER_FAIL,
                payload: errors,
            });
        }

    };

    const handleRegister = async ({ phone, countryCode, code, password }) => {
        try {
            dispatch({
                type: SET_AUTH_LOADING,
                payload: true,
            });
            await validateNumberAndSignUp({ phone, countryCode, code, password, isPro: true });

            dispatch({
                type: SET_AUTH_LOADING,
                payload: false,
            });

            const { data } = await axios.get(
                process.env.BACKEND_URL + '/api/v1/auth/login'
            );

            if (data?.status === 'pro') {
                const proData = await axios.get(`/api/v1/pro`);

                const payload = {
                    user: data,
                    pro: proData.data,
                };

                dispatch({
                    type: USER_LOADED,
                    payload,
                });
            } else {
                dispatch({
                    type: USER_LOADED,
                    payload: {
                        user: data,
                    },
                });

                // const messagesData = await axios.get('/api/v1/message/user');

                // dispatch({
                //   type: MESSAGES_LOADED,
                //   payload: messagesData.data,
                // });
            }
            await axios.post('/api/v1/pro/rate', {
                rate: starsClicked,
                comment: feedbackText,
                proId: router.query.pId,
                serviceId: router.query.sId,
            });
            setLoading(false);
            setVisible(true);
            setTimeout(() => {

                router.replace(`/`);
            }, 3000);

        } catch (err) {
            const { response } = err;

            const errors = response.data || [];

            dispatch({
                type: SET_AUTH_LOADING,
                payload: false,
            });

            dispatch({
                type: REGISTER_FAIL,
                payload: errors,
            });
        }
    };

    return (
        <>
            {active == 0 ?

                <ReviewWrapper>
                    <RateProTitle>
                        {t('ratePro {{proName}}', { ns: 'review', proName: pro.name })}
                    </RateProTitle>
                    <StarRating
                        rating={starsClicked}
                        handleClick={handleStarClick}
                    />
                    <FormWrapper>
                        <TextArea
                            cols={30}
                            rows={6}
                            required
                            placeholder={t('serviceExperience', { ns: 'review' })}
                            onChange={(e) => setFeedbackText(e.target.value)}
                        ></TextArea>
                        <StyledButton
                            loading={loading}
                            type='primary'
                            disabled={disableSubmit}
                            onClick={sendReview}
                        >
                            {submitR[lang]}
                        </StyledButton>
                    </FormWrapper>
                    <Modal
                        visible={visible}
                        footer={[

                        ]}
                    >
                        <div style={{ marginTop: '2rem' }}>
                            <p style={{ textAlign: 'center', fontWeight: 600, fontSize: '15px', lineHeight: '22px' }} dangerouslySetInnerHTML={{ __html: Feedback[lang] }}></p>
                        </div>


                    </Modal>
                </ReviewWrapper>
                : <>
                    {login ? (
                        <motion.div
                            animate={{ x: 0, opacity: 1 }}
                            initial={{ x: 100, opacity: 0 }}
                            transition={{
                                ease: 'easeInOut',
                            }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ textAlign: 'center', width: '50%', display: 'inline-block' }}>
                                    <Typography.Title style={{ fontWeight: 400 }} level={2}>{Log_in[lang]}</Typography.Title>
                                    {auth.errors && auth.errors.errors && (
                                        <span className="error">{auth.errors.errors[0].msg}</span>
                                    )}
                                    <LoginForm onSubmit={handleLogin} />
                                    <Row align='bottom' justify='center'>
                                        <Typography.Text>{Dont_have_an_account[lang]}
                                            {NoBreakSpace}
                                            <Typography.Link onClick={() => setLogin(false)}>
                                                {Sign_up[lang]}
                                            </Typography.Link>
                                        </Typography.Text>
                                    </Row>
                                </div>
                            </div>
                        </motion.div>
                    ) : (
                        <>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ textAlign: 'center', width: '50%', display: 'inline-block' }}>
                                    <Typography.Title style={{ fontWeight: 400 }} level={2}>{Create_Account[lang]}</Typography.Title>
                                    {auth.errors && auth.errors.errors && (
                                        <span className="error">{auth.errors.errors[0].msg}</span>
                                    )}
                                    <SignupForm onSubmit={handleRegister} />
                                    <Row align='bottom' justify='center'>
                                        <Typography.Text>{Already_have_an_account[lang]}
                                            {NoBreakSpace}
                                            <Typography.Link onClick={() => setLogin(true)}>
                                                {Log_in[lang]}
                                            </Typography.Link>
                                        </Typography.Text>
                                    </Row>
                                </div>
                            </div>
                        </>
                    )}
                </>}
        </>
    );
};

export default Review;
