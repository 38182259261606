import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { useSelector } from 'react-redux';
import { CheckboxState, District } from './types';

interface DistrictSelectProps {
    districts: District[];
    selected: CheckboxState;
    setSelection(e: CheckboxChangeEvent): void;
}
const DistrictSelect = ({ districts, selected, setSelection }: DistrictSelectProps) => {
    const lang = useSelector((state: { settings: { lang: 'eng' | 'uz' | 'ru' } }) => state.settings.lang);

    return (
        <div>
            {districts.map(district => (
                <div style={{ margin: '20px 0'}} key={district.id}>
                    <Checkbox name={district.id.toString()} onChange={setSelection} checked={selected[district.id] || false}>
                        {district[lang]}
                    </Checkbox>
                </div>
            ))}
        </div>
    );
};

export default DistrictSelect;
