interface GetSelectedTranslationArgs {
    selected: number;
    total: number;
    lang: 'eng' | 'ru' | 'uz';
}

const t = ({ selected, total }: Omit<GetSelectedTranslationArgs, 'lang'>) => ({
    eng: `${selected} of ${total} areas selected.`,
    ru: `Выбрано ${selected} из ${total} регионов.`,
    uz: `${total} ta joydan ${selected} tasi tanlandi.`,
});

export const getSelectedTranslation = ({ selected, total, lang }: GetSelectedTranslationArgs) => t({ selected, total })[lang];
